import React from 'react';

const SomSvg = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 10.5C20 15.7467 15.7467 20 10.5 20C5.25329 20 1 15.7467 1 10.5C1 5.25329 5.25329 1 10.5 1C15.7467 1 20 5.25329 20 10.5Z" fill="#B89252"/>
      <path d="M10.4196 12.2215C10.7371 12.2215 11.0305 12.1565 11.2999 12.0266C11.5693 11.8968 11.7906 11.7188 11.9638 11.4927C12.1369 11.2618 12.2356 10.9996 12.2596 10.7062H13.5296C13.5055 11.168 13.3492 11.5985 13.0605 11.9978C12.7767 12.3922 12.4039 12.7121 11.9421 12.9575C11.4803 13.198 10.9728 13.3183 10.4196 13.3183C9.83272 13.3183 9.3204 13.2148 8.88265 13.008C8.44971 12.8011 8.08892 12.5173 7.80029 12.1565C7.51648 11.7957 7.30241 11.382 7.15809 10.9154C7.01859 10.444 6.94884 9.94611 6.94884 9.42177V9.11871C6.94884 8.59437 7.01859 8.09889 7.15809 7.63227C7.30241 7.16084 7.51648 6.74474 7.80029 6.38395C8.08892 6.02317 8.44971 5.73935 8.88265 5.5325C9.3204 5.32565 9.83272 5.22222 10.4196 5.22222C11.0305 5.22222 11.5645 5.34729 12.0215 5.59744C12.4785 5.84277 12.8369 6.17951 13.0966 6.60764C13.3612 7.03096 13.5055 7.51201 13.5296 8.05078H12.2596C12.2356 7.72848 12.1442 7.43745 11.9854 7.17768C11.8315 6.91791 11.6198 6.71106 11.3504 6.55713C11.0858 6.39838 10.7756 6.31901 10.4196 6.31901C10.0107 6.31901 9.66676 6.40079 9.38775 6.56434C9.11355 6.72309 8.89468 6.93956 8.73112 7.21376C8.57237 7.48315 8.45692 7.7838 8.38477 8.11572C8.31742 8.44284 8.28375 8.77716 8.28375 9.11871V9.42177C8.28375 9.76331 8.31742 10.1 8.38477 10.432C8.45211 10.7639 8.56516 11.0645 8.7239 11.3339C8.88746 11.6033 9.10634 11.8198 9.38053 11.9833C9.65954 12.1421 10.0059 12.2215 10.4196 12.2215Z" fill="white"/>
      <path d="M6.27778 14.6171H14.013V15.3387H6.27778V14.6171Z" fill="white"/>
    </svg>
  );
};

export default SomSvg;