import React, {useState} from 'react';
import {Box, Input, InputGroup, InputLeftElement, InputRightElement} from "@chakra-ui/react";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";

export interface IInputProps {
  name: string;
  placeholder: string;
  type?: string;
  value?: string;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  variant?: "outline" | "flushed";
  required?: boolean;
  leftIcon?: null | JSX.Element
  rightIcon?: null | JSX.Element
  onCLick?: () => void
  mini?: boolean
}

const InputComponent = ({
                          leftIcon = null,
                          rightIcon = null,
                          name,
                          handleChange,
                          required,
                          type = 'text',
                          value,
                          variant = "outline",
                          placeholder,
                          onCLick,
                          mini
                        }: IInputProps) => {
  const [show, setShow] = useState(false)
  const inputType = type
  type = show ? 'text' : type

  const rightElement = inputType === "password" || rightIcon
  return (
    <InputGroup mb='11px'>
      {leftIcon && <InputLeftElement
        w="3rem"
        h="100%"
        display="flex"
        alignItems="center"
      >
        {leftIcon && <Box
          fontSize="24px"
          color="#202020"
        >{leftIcon}</Box>}
      </InputLeftElement>}
      <Input
        onChange={handleChange}
        value={value}
        name={name}
        type={type}
        placeholder={placeholder}
        variant={variant}
        h={mini ? '38px' : '50px'} w='100%' px={1}
        rounded={variant === 'flushed' ? '0' : '14px'}
        border={variant === 'flushed' ? 'none' : `1px solid #2020204D`}
        borderBottom={`1px solid #2020204D`}
        fontSize='16px'
        fontWeight='400' fontFamily={"Roboto"}
        lineHeight='21px' color={"#202020"}
        _placeholder={{opacity: '30%'}}
        isRequired={required}
      />
      {rightElement && <InputRightElement
        w="3rem"
        h="100%"
        display="flex"
        alignItems="center"
      >
        {rightIcon && <Box
          onClick={() => onCLick && onCLick()}
          cursor='pointer'
          fontSize="24px"
          color="#A8A8A8"
        >{rightIcon}</Box>}

        {inputType === "password" && <Box
          onClick={() => setShow(!show)}
          fontSize="24px"
          color="#A8A8A8"
          cursor="pointer"
        >
          {!show ? <AiOutlineEyeInvisible/> : <AiOutlineEye/>}
        </Box>}
      </InputRightElement>}
    </InputGroup>
  );
};

export default InputComponent;