import React from 'react';
import {Box, Flex} from "@chakra-ui/react";
import OrderCard from "../OrderCard";
import DefText from "../../../components/ui/text/DefText";
import {onOpenAccount} from "../../../components/forms/reducer/slice";
import DefButton from "../../../components/ui/Buttons/DefButton";
import {useAppDispatch, useAppSelector} from "../../../config/hooks/redux";

const EmptyCard = () => {
  const {all_accounts} = useAppSelector(s => s.ProfileReducer)
  const dispatch = useAppDispatch()
  return (
    <>
      <OrderCard colorLine={"#0297F6"}>
        <Flex justifyContent='center' alignItems='center' w='100%' h='105px'>
          <Box textAlign='center' opacity='0.5' w='57%'>
            {!!all_accounts.length && <DefText>Ожидайте подтверждения администратора. </DefText>}
            {!all_accounts.length && <DefText>Чтобы дальше пройти, нужно добавить инстаграм никнейм</DefText>}
          </Box>
        </Flex>
      </OrderCard>
      <Box mt='9px'>
        <DefButton
          onClick={() => dispatch(onOpenAccount())}
          bg="#FFFFFF" color="#0297F6" type="button">Добавить Аккаунт</DefButton>
      </Box>
    </>
  );
};

export default EmptyCard;