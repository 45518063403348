import React from 'react';


const LikeSvg = ({size=26}: {size?: number}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.81 3.3584C15.8491 3.3584 14.0941 4.31173 13 5.77423C11.9058 4.31173 10.1508 3.3584 8.18996 3.3584C4.86413 3.3584 2.16663 6.06673 2.16663 9.41423C2.16663 10.7034 2.37246 11.8951 2.72996 13.0001C4.44163 18.4167 9.71746 21.6559 12.3283 22.5442C12.6966 22.6742 13.3033 22.6742 13.6716 22.5442C16.2825 21.6559 21.5583 18.4167 23.27 13.0001C23.6275 11.8951 23.8333 10.7034 23.8333 9.41423C23.8333 6.06673 21.1358 3.3584 17.81 3.3584Z" fill="#FE6D72"/>
    </svg>
  );
};

export default LikeSvg;