import React from 'react';

const FollowerSvg = ({size=26}: {size?: number}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.2217 2.06909H7.77835C5.48168 2.06909 3.59668 3.95409 3.59668 6.25076V21.5149C3.59668 23.4649 4.99418 24.2883 6.70585 23.3458L11.9925 20.4099C12.5558 20.0958 13.4658 20.0958 14.0183 20.4099L19.305 23.3458C21.0167 24.2991 22.4142 23.4758 22.4142 21.5149V6.25076C22.4033 3.95409 20.5292 2.06909 18.2217 2.06909ZM16.9217 9.78242L12.5883 14.1158C12.4258 14.2783 12.22 14.3541 12.0142 14.3541C11.8083 14.3541 11.6025 14.2783 11.44 14.1158L9.81501 12.4908C9.50085 12.1766 9.50085 11.6566 9.81501 11.3424C10.1292 11.0283 10.6492 11.0283 10.9633 11.3424L12.0142 12.3933L15.7733 8.63409C16.0875 8.31992 16.6075 8.31992 16.9217 8.63409C17.2358 8.94826 17.2358 9.46826 16.9217 9.78242Z" fill="#0297F6"/>
    </svg>

  );
};

export default FollowerSvg;