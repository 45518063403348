import React, {useEffect, useState} from 'react';
import {
  Box,
  HStack, Link,
  Modal, ModalCloseButton,
  ModalContent,
  ModalOverlay,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";
import DefText from "../../ui/text/DefText";
import {useAppDispatch, useAppSelector} from "../../../config/hooks/redux";
import {onCloseOTPInput, onOpenOTPInput} from "../reducer/slice";
import {useNavigate} from "react-router-dom";

const OtpInput = ({onSubmit}: {onSubmit: (code: string) => void}) => {
  const dispatch = useAppDispatch()
  const {isOpenOtpCode} = useAppSelector(s => s.LoginReducer)
  const [code, setCode] = useState('')
  const handleChange = (e: string) => setCode(e)

  useEffect(() => {
    if (code.length === 6) {
      onSubmit(code)
      // dispatch(onConfirmCode({email, code}, navigate))
    }
  }, [code])
  return (
    <>
      <Link
        onClick={() => dispatch(onOpenOTPInput())}
        display='flex' color='#217CE6'
        alignItems='end' mx='auto'>
        Код?
      </Link>

      <Modal isOpen={isOpenOtpCode} onClose={() => {}} size={{md: 'md', base: 'sm'}} isCentered={true}>
        <ModalOverlay/>
        <ModalContent px={8} bg='#F5F5F5' pt={10} pb={6} rounded='24px'>
          <ModalCloseButton onClick={() => dispatch(onCloseOTPInput())}/>
          <Box textAlign='center'>
            <DefText children={"Введите 6 значный пароль, который был отправлен на вашу электронную почту"}/>
          </Box>
          <HStack mx='auto' my={5}>
            <PinInput
              type='number' size='lg'
              onChange={handleChange}
              value={code}
              placeholder=''
              // isDisabled={isDisabled}
            >
              <PinInputField bg='white'/>
              <PinInputField bg='white'/>
              <PinInputField bg='white'/>
              <PinInputField bg='white'/>
              <PinInputField bg='white'/>
              <PinInputField bg='white'/>
            </PinInput>
          </HStack>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OtpInput;