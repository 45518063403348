import React, {useState} from 'react';
import CustomerButton from "../CustomerButton";
import FollowerSvg from "../../../assets/svg/FollowerSvg";
import InputComponent from "../../../components/ui/inputs/InputComponent";
import {Box, Flex} from "@chakra-ui/react";
import SomSvg from "../../../assets/svg/SomSvg";
import DefText from "../../../components/ui/text/DefText";
import {useAppDispatch, useAppSelector} from "../../../config/hooks/redux";
import {IFollowValue} from "../../../models/orders";
import {createFollow} from "../reducer/actions";
import toast from "react-hot-toast";

const Followers = () => {
  const {follow: price} = useAppSelector(s => s.ProfileReducer.price)
  const dispatch = useAppDispatch()
  const [value, setValue] = useState<IFollowValue>({
    price,
    url: '',
    quantity: '',
    total: ''
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({...value, [e.target.name]: e.target.value})
  }
  const onSubmit = (onClose: () => void) => {
    if (value.url.trim().includes('https://www.instagram.com/')){
      dispatch(createFollow({...value, total: +price * +value.quantity}, onClose))
    }else {
      toast.error('Эта ссылка не принадлежит инстаграму')
    }
  }
  return (
    <CustomerButton
      title={"Подписка"}
      bg={"#0297F61A"}
      icon={<FollowerSvg/>}
      onClick={onSubmit}
      total_price={+price * +value.quantity || price}
    >
      <>
        <InputComponent
          handleChange={handleChange}
          name={"url"}
          placeholder={"Ссылка профиля"}
          value={value.url}
          leftIcon={<FollowerSvg size={20}/>}
          type={"url"}
          required={true}
        />
        <InputComponent
          handleChange={handleChange}
          name={"quantity"}
          value={value.quantity}
          placeholder={"Количество подписчиков"}
          leftIcon={<FollowerSvg size={20}/>}
          type={"number"}
          required={true}
        />

        <Box mt='38px'>
          <Flex alignItems='center' justifyContent='center' mb='18px'>
            <SomSvg/>
            <Box ml={3}><DefText fontSize='14px' children={`Подписка на профиль по ${price}с`}></DefText></Box>
          </Flex>
          {/*<DefButton isLoading={isLoading}>Разместить заказ {+price * +value.quantity || price}с</DefButton>*/}
        </Box>
      </>
    </CustomerButton>
  );
};

export default Followers;