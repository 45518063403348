import React from 'react';
import {Box} from "@chakra-ui/react";
import {INTERFACE_PADDING_X, NAVBAR_HEIGHT} from "../../config/_variables";

interface Props {
  children: JSX.Element
  offsetTop: number
}
const GrayContainer = ({children, offsetTop}: Props) => {
  return (
    <Box
      mx="auto" minH={(window.innerHeight - offsetTop - parseInt(NAVBAR_HEIGHT)) + "px"} w='100%' bg='#F3F3F3'
      pt='30px' borderTopRadius="34px"
      px={INTERFACE_PADDING_X} mt='26px'
    >
      {children}
    </Box>
  );
};

export default GrayContainer;