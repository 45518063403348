import {AppDispatch} from "../../../redux/store";
import PRIVATE_API from "../../../api";
import {profileState} from "./slice";
import {IAccount, IPrice, IProfile} from "../../../models/user";
import {loginSlice} from "../../forms/reducer/slice";
import {toastCreator} from "../../../config/toast";
import toast from "react-hot-toast";
import {WHATSAPP_LINK} from "../../../config/admin";
import {navigateToUrl} from "../../../config/helpers";

export const getProfile = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(profileState.actions.setLoading())
    const {data} = await PRIVATE_API.get<IProfile>("account/profile/")
    dispatch(profileState.actions.getProfile(data))
    dispatch(getPrices())
    dispatch(getAccounts())
  } catch (e: any) {
    dispatch(profileState.actions.getProfileError(e.response?.data?.detail));
  }
}

export const editProfile = (value: any, id: number, onClose: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loginSlice.actions.onLoading())
    await PRIVATE_API.patch(`account/profile/update/${id}/`, value)
    toastCreator("success", "Данные обновлены");
    dispatch(getProfile())
    dispatch(loginSlice.actions.onLogin(null));
    onClose()
  } catch (e: any) {
    toast.error(e.response?.data?.email || e.response?.data?.detail || "произошла ошибка");
    dispatch(loginSlice.actions.onLogin(e.response?.data?.message));
  }
}



export const getPrices = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(profileState.actions.setLoading())
    const {data} = await PRIVATE_API.get<IPrice>("task/price/")
    dispatch(profileState.actions.getPrices(data))
  } catch (e: any) {
    dispatch(profileState.actions.getProfileError(e.response?.data?.detail));
  }
}

export const getAccounts = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(profileState.actions.setLoading())
    const {data} = await PRIVATE_API.get<IAccount[]>("account/instagram/")
    dispatch(profileState.actions.getAccounts(data))
  } catch (e: any) {
    dispatch(profileState.actions.getProfileError(e.response?.data?.detail));
  }
}

export const addAccount = (value: any, role: number, onClose: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loginSlice.actions.onLoading())
    const {data} = await PRIVATE_API.post("account/instagram/create/", {...value})
    toastCreator("success", "Аккаунт добавлен");
    dispatch(getProfile())
    // if (!!role) navigateToUrl(sendToWhatsApp(value.account))
    navigateToUrl(sendToWhatsApp(value.account), true)
    dispatch(loginSlice.actions.onLogin(null));
    onClose()
  } catch (e: any) {
    toast.error(e.response?.data?.detail || "Произошла ошибка");
    dispatch(loginSlice.actions.onLogin(e.response?.data?.message));
  }
}

export const editAccount = (value: any, id: number, onClose: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loginSlice.actions.onLoading())
    await PRIVATE_API.patch(`account/instagram/update/${id}/`, {...value})
    toastCreator("success", "Аккаунт обновлен");
    dispatch(getProfile())
    // if (!!role) navigateToUrl(sendToWhatsApp(value.account))
    dispatch(loginSlice.actions.onLogin(null));
    onClose()
  } catch (e: any) {
    toast.error(e.response?.data?.detail || "Произошла ошибка");
    dispatch(loginSlice.actions.onLogin(e.response?.data?.detail));
  }
}


export const sendToWhatsApp = (account: string) => {
  return `
${WHATSAPP_LINK}?text=${encodeURIComponent(`Приветствую!\n`)}\n
${encodeURIComponent(`\n`)}\n
 
${encodeURIComponent(`Я хочу добавить свой инстаграм аккаунт @${account} в платформу Demanbeg Like\n`)}\n \b
${encodeURIComponent(`\n`)}\n

${encodeURIComponent(`Куда отправить деньги чтобы вы одобрили мой аккаунт?\n`)}\n \b
${encodeURIComponent(`\n`)}\n

https://www.instagram.com/${account}/ \n`
}



