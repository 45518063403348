import React from "react";
import {
  Box,
  Drawer,
  DrawerBody, DrawerCloseButton,
  DrawerContent, DrawerHeader,
  DrawerOverlay, Progress,
} from "@chakra-ui/react";
import {INTERFACE_WIDTH} from "../../../config/_variables";
import TitleComponents from "../text/TitleComponent";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: JSX.Element;
  title: string;
  isLoading?: boolean
}

function Placement({
                     isOpen,
                     onClose,
                     children,
                     title,
                     isLoading = false
                   }: Props) {

  return (
    <Drawer placement={"bottom"} onClose={onClose} isOpen={isOpen}>
      <DrawerHeader></DrawerHeader>
      <DrawerOverlay bg="#000000B2"/>
      <DrawerContent
        w={INTERFACE_WIDTH}
        maxH="80vh" mx="auto"
        bg='transparent'
        px='10px' pb='10px'
        borderTopRadius="24px"
        className="scroll" overflow='auto'
      >
        <DrawerCloseButton color='#00000080' mx='10px' fontSize='14px' mt={2}/>
        <Box
          rounded="24px"
          w={'100%'}
          h='100%'
          pb='30px' pt='14px'
          bg={"#F5F5F5"}
        >
          {/*<Box onClick={onClose} mb='19px'>*/}
          {/*  <Box bg={system_color} maxW="79px" h="3px" mx="auto" borderRadius="17px"/>*/}
          {/*</Box>*/}

          <TitleComponents>{title}</TitleComponents>
          <DrawerBody className="unscroll" px={4} mt='31px' overflow='auto' maxH='420px'>
            <Box h='4px' mb='10px'>
              {isLoading && <Progress size='xs' bg='none' isIndeterminate/>}
            </Box>
            {children}
          </DrawerBody>
        </Box>
      </DrawerContent>
    </Drawer>
  );
}

export default Placement;
