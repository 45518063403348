import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {LoginReducer} from "../components/forms/reducer/slice";
import {PostCustomReducer} from "../pages/customer/reducer/slice";
import {ProfileReducer} from "../components/profile-header/reducer/slice";
import {OrderReducer} from "../pages/executor/reducer/slice";


const rootReducer = combineReducers({
  LoginReducer,
  PostCustomReducer,
  ProfileReducer,
  OrderReducer,
});


const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    devTools: false,
    // middleware: (getDefaultMiddleware) =>
    //   getDefaultMiddleware().concat(shopApi.middleware),
  });
};

export const store = setupStore()

export type RootState = ReturnType<typeof rootReducer>;
type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

