export const NAVBAR_HEIGHT = "64px"

export const SAVE_BTN_HEIGHT = "48px"

export const HEADER_HEIGHT = "56px"

export const INTERFACE_WIDTH = "576px"

export const INTERFACE_PADDING_X = "4"

export const INNER_WIDTH = window.innerWidth





