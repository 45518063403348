import React from 'react';
import {Box, Flex, Spinner} from "@chakra-ui/react";
import {INTERFACE_WIDTH} from "../../config/_variables";

const Loader = () => {
// gg
  return (
    <Box position='fixed' left='0' right='0' top='0' bottom='0' zIndex='102'>
      <Flex maxW={INTERFACE_WIDTH} mx='auto' justifyContent='center' alignItems='center'
            bg={'rgba(0,0,0,0.6)'} zIndex='101' h='100%'>
        <Spinner color='white' size='lg'/>

      </Flex>
    </Box>
  );
};

export default Loader;