import React from 'react';

const InstagramLogoSvg = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill="url(#paint0_linear_195_2412)"/>
      <path d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill="black" fillOpacity="0.2"/>
      <path d="M39.3164 18.6526C38.9105 18.6526 38.5138 18.7729 38.1764 18.9984C37.8389 19.2239 37.5759 19.5444 37.4206 19.9193C37.2653 20.2943 37.2247 20.7068 37.3038 21.1049C37.383 21.5029 37.5784 21.8686 37.8654 22.1555C38.1524 22.4425 38.518 22.6379 38.9161 22.7171C39.3141 22.7963 39.7267 22.7557 40.1016 22.6003C40.4766 22.445 40.7971 22.182 41.0226 21.8446C41.248 21.5071 41.3684 21.1104 41.3684 20.7046C41.3684 20.1604 41.1522 19.6384 40.7674 19.2536C40.3825 18.8688 39.8606 18.6526 39.3164 18.6526Z" fill="white"/>
      <path d="M30.1431 21.3406C28.4229 21.3366 26.7403 21.8438 25.3089 22.7978C23.8774 23.7518 22.7618 25.1097 22.1035 26.699C21.4453 28.2882 21.2741 30.0373 21.6117 31.724C21.9493 33.4108 22.7805 34.9592 23.9997 36.1726C25.219 37.3861 26.7713 38.2099 28.4597 38.5395C30.148 38.8691 31.8962 38.6896 33.4823 38.0238C35.0685 37.358 36.421 36.2359 37.3682 34.8C38.3154 33.3641 38.8146 31.6791 38.8024 29.9589C38.7862 27.6711 37.8674 25.4822 36.2458 23.8683C34.6242 22.2544 32.4309 21.346 30.1431 21.3406ZM30.1431 35.5608C29.0424 35.5649 27.9652 35.2422 27.048 34.6337C26.1309 34.0251 25.4149 33.158 24.9909 32.1423C24.5669 31.1265 24.4538 30.0078 24.666 28.9277C24.8783 27.8477 25.4063 26.8549 26.1832 26.0752C26.96 25.2954 27.9508 24.7637 29.0301 24.5475C30.1094 24.3313 31.2285 24.4402 32.2458 24.8605C33.2631 25.2807 34.1329 25.9935 34.7448 26.9084C35.3568 27.8233 35.6834 28.8993 35.6834 30C35.6673 31.4625 35.0778 32.8602 34.0417 33.8925C33.0056 34.9248 31.6056 35.5091 30.1431 35.5198V35.5608Z" fill="white"/>
      <path d="M37.0178 47.5651H22.9822C20.1811 47.5596 17.4964 46.4445 15.5157 44.4639C13.535 42.4832 12.4199 39.7984 12.4145 36.9973V22.9413C12.4199 20.1402 13.535 17.4554 15.5157 15.4747C17.4964 13.4941 20.1811 12.379 22.9822 12.3735H37.0178C39.8189 12.379 42.5036 13.4941 44.4843 15.4747C46.465 17.4554 47.5801 20.1402 47.5855 22.9413V36.9973C47.5801 39.7984 46.465 42.4832 44.4843 44.4639C42.5036 46.4445 39.8189 47.5596 37.0178 47.5651ZM22.9822 15.6772C21.0573 15.6826 19.2128 16.4497 17.8517 17.8108C16.4907 19.1719 15.7236 21.0164 15.7182 22.9413V36.9973C15.7236 38.9203 16.4913 40.7627 17.853 42.1205C19.2147 43.4784 21.0592 44.2409 22.9822 44.2409H37.0178C38.9408 44.2409 40.7853 43.4784 42.147 42.1205C43.5087 40.7627 44.2764 38.9203 44.2818 36.9973V22.9413C44.2764 21.0164 43.5093 19.1719 42.1482 17.8108C40.7871 16.4497 38.9427 15.6826 37.0178 15.6772H22.9822Z" fill="white"/>
      <defs>
        <linearGradient id="paint0_linear_195_2412" x1="51.2175" y1="8.78249" x2="8.78249" y2="51.2175" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBE18A"/>
          <stop offset="0.21" stopColor="#FCBB45"/>
          <stop offset="0.38" stopColor="#F75274"/>
          <stop offset="0.52" stopColor="#D53692"/>
          <stop offset="0.74" stopColor="#8F39CE"/>
          <stop offset="1" stopColor="#5B4FE9"/>
        </linearGradient>
      </defs>
    </svg>

  );
};

export default InstagramLogoSvg;