import React, {useEffect, useState} from 'react';
import {Box, Button, Center, Flex, Link, Radio, RadioGroup, Spinner, Stack} from "@chakra-ui/react";
import InputComponent from "../../ui/inputs/InputComponent";
import {CiMail} from "react-icons/ci";
import {PiKeyLight} from "react-icons/pi";
import DefText from "../../ui/text/DefText";
import DefButton from "../../ui/Buttons/DefButton";
import {GoArrowUpRight} from "react-icons/go";
import {useLocation, useNavigate} from "react-router-dom";
import {ILoginValue} from "../../../models/user";
import {useAppDispatch, useAppSelector} from "../../../config/hooks/redux";
import {onConfirmCode, onLogin, onSignUp} from "../reducer/actions";
import OtpInput from "./OtpInput";
import {onCloseOTPInput} from "../reducer/slice";

interface Props {
  isLogin: boolean
}

const Login = ({isLogin}: Props) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
const {pathname} = useLocation()
  const {isLoading, isOpenOtpCode} = useAppSelector(s => s.LoginReducer)
  const [role, setRole] = useState('')
  const [value, setValue] = useState<ILoginValue>({
    email: "",
    password: ""
  })
  const navigateTo = () => {
    if (isLogin) navigate('/sign-up')
    else navigate('/')
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({...value, [e.target.name]: e.target.value})
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (isLogin){
      dispatch(onLogin(value, Number(role), isLogin, navigate))
    }else {
      dispatch(onSignUp({...value, role}))
    }
  }

  const confirmCode = (code: string) => {
    dispatch(onConfirmCode({email: value.email, code}, navigate))
  }

  useEffect(() => {
    dispatch(onCloseOTPInput())
  }, [pathname])
  return (
    <Box>
      <Flex justifyContent='center' mb='27px'>
        {isLoading && <Spinner/>}
        {!isLoading && <DefText fontSize='20px' fontWeight='500'>{isLogin ? "Вход" : "Регистрация"}</DefText>}
      </Flex>
      <form onSubmit={onSubmit}>
        {!isLogin && <RadioGroup
          onChange={setRole}
          value={role}
        >
          <Stack direction='row' spacing='10px' mb='11px'>
            <Button
              bg='transparent' variant='none'
              h='50px' rounded='14px'
              border={`1px solid #2020204D`}
              fontSize='16px' w='100%'
              fontWeight='400' fontFamily={"Roboto"}
              lineHeight='21px' color={"#202020"}
              _active={{opacity: '1'}}
            >
              <Radio value='1' isRequired={true}>Исполнитель</Radio>
            </Button>
            <Button
              bg='transparent' variant='none'
              h='50px' rounded='14px'
              border={`1px solid #2020204D`}
              fontSize='16px' w='100%'
              fontWeight='400' fontFamily={"Roboto"}
              lineHeight='21px' color={"#202020"}
              _active={{opacity: '1'}}
            >
              <Radio value='0' isRequired={true}>Заказчик</Radio>
            </Button>
          </Stack>
        </RadioGroup>}
        <InputComponent
          handleChange={handleChange}
          leftIcon={<CiMail/>}
          value={value.email}
          name={"email"}
          type={"email"}
          placeholder={"Электронная почта "}
          required={true}
        />
        <InputComponent
          handleChange={handleChange}
          leftIcon={<PiKeyLight/>}
          name={"password"}
          value={value.password}
          placeholder={"Пароль"}
          type={"password"}
          required={true}
        />
        <Center my={5}>
          <Link
            onClick={navigateTo}
            display='flex' color='#217CE6'
            alignItems='end' mx='auto'>{isLogin ? "Зарегистрироваться" : "Войти"}
            <Box fontSize='1.2rem' color='#3E3526'><GoArrowUpRight/></Box>
          </Link>
          {isLogin && <Link
            onClick={() => navigate("/forgot-password")}
            display='flex' color='#217CE6'
            alignItems='end' mx='auto'>Забыли пароль?
          </Link>}
          {!isLogin && isOpenOtpCode && <OtpInput onSubmit={confirmCode}/>}
        </Center>

        <DefButton disabled={isLoading}>{isLogin ? 'Войти' : 'Регистрироваться'}</DefButton>
        {/*<Button type="submit">{isLogin ? 'Войти' : 'Регистрироваться'}</Button>*/}
      </form>
    </Box>
  );
};

export default Login;