import React from 'react';
import {Box, Center, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text} from "@chakra-ui/react";
import {useAppSelector} from "../../../config/hooks/redux";
import OrderCard from "../OrderCard";
import OrderCardItems from "../../../components/ui/card/OrderCardItems";
import {blue_color, green_color, order_status, red_color} from "../../../config/colors";
import FollowerSvg from "../../../assets/svg/FollowerSvg";
import LikeSvg from "../../../assets/svg/LikeSvg";
import CommentSvg from "../../../assets/svg/CommentSvg";

const ListOrders = () => {
  const {price, profile: {role}} = useAppSelector(s => s.ProfileReducer)
  const {follow, likes, comments} = useAppSelector(s => s.OrderReducer.orders)

  function headerTabPanel(quantity: number, price: string | number) {
    return <Flex alignItems='center' my={2} color='#3E3526'>
      <Box>количество задач: &nbsp;{quantity}</Box>
      <Flex ml={2}>по&nbsp;{+price / 2} <Text ml='2px' textDecoration='underline'>c</Text></Flex>
    </Flex>
  }

  return (
    <Tabs size='md' variant='enclosed' w='100%' isFitted>
      <TabList w='100%' fontFamily='Roboto' h='48px' fontWeight='400' overflowX='auto' overflowY='hidden'
               className='unscroll'>
        <Tab h='100%'>Подписка</Tab>
        <Tab h='100%'>Лайк</Tab>
        <Tab h='100%'>Комментарий</Tab>
        {/*<Tab h='100%'>Другие</Tab>*/}
      </TabList>
      <TabPanels py={3}>
        <TabPanel p='0'>
          {!follow.length && <Center>Пусто</Center>}
          {!!follow.length && headerTabPanel(follow.length, price.follow)}
          {follow.map(el => (
            <OrderCard
              colorLine={blue_color} key={el.id}
            >
              <OrderCardItems
                order_type={"follow"}
                icon={<FollowerSvg/>}
                color={order_status[el.status]}
                topLeft={"Подписка"}
                middleLeft={"Подписчиков:"}
                order={el}
                role={role}
              />
            </OrderCard>
          ))}
        </TabPanel>
        <TabPanel p='0'>
          {!likes.length && <Center>Пусто</Center>}
          {!!likes.length && headerTabPanel(likes.length, price.like)}
          {likes.map(el => (
            <OrderCard
              colorLine={red_color} key={el.id}
            >
              <OrderCardItems
                order_type={"like"}
                icon={<LikeSvg/>}
                color={order_status[el.status]}
                topLeft={"Лайк"}
                order={el}
                middleLeft={"Лайков:"}
                role={role}
              />
            </OrderCard>
          ))}
        </TabPanel>
        <TabPanel p='0'>
          {!comments?.length && <Center>Пусто</Center>}
          {!!comments?.length && headerTabPanel(comments.length, price.comment)}
          {comments?.map(el => (
            <OrderCard
              colorLine={green_color} key={el.id}
            >
              <OrderCardItems
                order_type={"comment"}
                icon={<CommentSvg/>}
                color={order_status[el.status]}
                topLeft={"Комментарий"}
                order={el}
                middleLeft={"Комментариев:"}
                role={role}
                comments={el.commenttexts}
              />
            </OrderCard>
          ))}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ListOrders;