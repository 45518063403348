import React from 'react';
import {Box} from "@chakra-ui/react";
import RoutesRender from "./routes/routes-render";

const App = () => {
  return (
    <Box>
      <RoutesRender/>
    </Box>
  );
};

export default App;