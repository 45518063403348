import React from 'react';
import {Button} from "@chakra-ui/react";
interface Props {
  children: any | JSX.Element;
  onClick?: () => void;
  type?: "button" | "submit";
  disabled?: boolean;
  bg?: string;
  color?: string;
}
const DefButton = ({onClick = () => {}, type = 'submit', disabled, children,
                     bg = '#0297F6', color = '#FFFFFF'}: Props) => {
  return (
    <Button
      onClick={onClick}
      type={type}
      variant='none'
      bg={bg}
      h={'48px'} w='100%'
      rounded='14px'
      color={color}
      fontSize='16px'
      fontWeight='600'
      lineHeight='21px'
      _hover={{bg}}
      isDisabled={disabled}
      // isLoading={isLoading}
    >{children}</Button>
  );
};

export default DefButton;