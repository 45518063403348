import React from 'react';
import {Avatar, Box, Flex, Heading, Text} from "@chakra-ui/react";
import {HEADER_HEIGHT} from "../../config/_variables";
import {MdKeyboardArrowDown} from "react-icons/md";
import Placement from "../ui/placement";
import {BsCheckCircleFill} from "react-icons/bs";
import AddAccount from "./AddAccount";
import {LuCircle} from "react-icons/lu";
import {useAppDispatch, useAppSelector} from "../../config/hooks/redux";
import {IAccount} from "../../models/user";
import {editProfile} from "./reducer/actions";
import {onCloseAccount, onOpenAccount} from "../forms/reducer/slice";

const ProfileHeader = () => {
  const dispatch = useAppDispatch()
  const {profile: {role_label, avatar, full_name, account_id, id, role}, accounts} = useAppSelector(s => s.ProfileReducer)
  const {isLoading, isOpenAccount} = useAppSelector(s => s.LoginReducer)
  const current_acc: IAccount | undefined = accounts.find(el => el.id === account_id)

  const onClose = () => {
    dispatch(onCloseAccount())
  }

  const changeAccount = (acc_id: number) => {
    if (account_id !== acc_id){
      dispatch(editProfile({account_id: acc_id}, id, onClose))
    }
  }
  return (
    <Flex justifyContent='space-between' alignItems='center' h={HEADER_HEIGHT}>
      <Flex h='100%' alignItems='center'>
        <Avatar size='lg' src={avatar}/>
        <Box ml='10px'>

          {!role && <Flex
            alignItems='center'
            cursor='pointer' _active={{opacity: .8}}>
            <Heading
              color='#202020'
              fontFamily='Roboto'
              fontWeight='500'
              lineHeight='21px'
              fontSize='22px'
            >{full_name}</Heading>
          </Flex>}

          { !!role && <Flex
            onClick={() => dispatch(onOpenAccount())}
            alignItems='center'
            cursor='pointer' _active={{opacity: .8}}>
            <Heading
              color='#202020'
              fontFamily='Roboto'
              fontWeight='500'
              lineHeight='21px'
              fontSize='22px'
            >{!!accounts.length ? current_acc?.account : 'Добавить аккаунт'}</Heading>
            <Box color='#202020' w='24px' fontSize='1.4rem'>
              <MdKeyboardArrowDown/>
            </Box>
          </Flex>}

          <Placement isOpen={isOpenAccount} onClose={onClose} title={""} isLoading={isLoading}>
            <Box>
              {accounts?.map(account => (
                <Flex
                  key={account.id}
                  onClick={() => changeAccount(account.id)}
                  justifyContent='space-between' alignItems='center' mb='26px' cursor='pointer' _active={{opacity: .8}}>
                  <Flex alignItems='center'>
                    <Avatar w='44px' h='44px' src=''/>
                    <Heading
                      ml='10px'
                      color='#202020'
                      fontFamily='Roboto'
                      fontWeight='500'
                      lineHeight='21px'
                      fontSize='18px'
                    >{account.account}</Heading>
                  </Flex>
                  <Box color={account_id === account.id ? '#0297F6' : '#202020'} fontSize='1.4rem'>
                    {account_id === account.id ? <BsCheckCircleFill/> : <LuCircle/>}
                  </Box>
                </Flex>
              ))}
              <AddAccount/>

            </Box>
          </Placement>

          <Text
            color='#202020'
            fontFamily='Roboto'
            fontWeight='400'
            lineHeight='15.46px'
            fontSize='14px'
          >{role_label}</Text>
        </Box>
      </Flex>


      {/*<Button*/}
      {/*  px='6.5px' pb='12.17px' pt='10.33px'*/}
      {/*  w='36px' h='40px'*/}
      {/*  bg='#0000001A'*/}
      {/*  rounded='12px'*/}
      {/*  color='#202020CC'*/}
      {/*  fontSize='2rem'*/}
      {/*>*/}
      {/*  <PiBellFill/>*/}
      {/*</Button>*/}
    </Flex>
  );
};

export default ProfileHeader;