import React from 'react';
import {
  Box,
  Flex, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger,
  Stack, Text,
} from "@chakra-ui/react";
import DefText from "../ui/text/DefText";
import DefButton from "../ui/Buttons/DefButton";
import {GoArrowUpRight} from "react-icons/go";
import {useAppSelector} from "../../config/hooks/redux";
import {navigateToUrl} from "../../config/helpers";
import {addMoney} from "./actions";
import GetMoneyButton from "./getMoneyButton";
import {AiOutlineExclamationCircle} from "react-icons/ai";

interface Props {
  page: 'customer' | 'executor';
}

const Balance = ({page}: Props) => {
  const {balance, email, applied_balance} = useAppSelector(s => s.ProfileReducer.profile)
  const inCustomer = page === "customer"

  const addBalance = () => {
    navigateToUrl(addMoney(email), false)
  }
  return (
    <>
      <Stack
        spacing='2'
        mt='26px'
      >
        <Flex bg='#F3F3F3' rounded='14px'>
          <Flex
            w='100%'
            flexDirection='column'
            alignItems='center' justifyContent='center'
            h='100px'
          >
            <Box mb='18px'><DefText>Ваш баланс</DefText></Box>
            <DefText fontSize='32px' fontWeight='600' children={`${balance} с`}/>
          </Flex>
          {!!applied_balance && <Flex
            w='100%'
            flexDirection='column'
            alignItems='center' justifyContent='center'
            h='100px'
          >
            <Flex gap='2' mb='18px'>
              <DefText color='green'>В ожидании</DefText>
              <Popover>
                <PopoverTrigger>
                  <Box cursor='pointer'>
                    <AiOutlineExclamationCircle color='green' fontSize='18px'/>
                  </Box>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow/>
                  <PopoverCloseButton/>
                  <PopoverHeader>Мы проверяем!</PopoverHeader>
                  <PopoverBody>эти деньги будут добавлены на основной счет после проверки</PopoverBody>
                </PopoverContent>
              </Popover>
            </Flex>
            <DefText fontSize='22px' fontWeight='400' color='green' children={`+${applied_balance} сом`}/>
          </Flex>}
        </Flex>
        {inCustomer &&
          <DefButton type='button' onClick={addBalance}>
            <Flex>Пополнить деньги
              <Box fontSize='1.4rem' ml={1}><GoArrowUpRight/></Box></Flex>
          </DefButton>}
        {!inCustomer && <GetMoneyButton/>}
      </Stack>
    </>
  );
};

export default Balance;