import React from 'react';
import CustomerButton from "../CustomerButton";
import OthersSvg from "../../../assets/svg/OthersSvg";
import InputComponent from "../../../components/ui/inputs/InputComponent";
import {Box, Flex} from "@chakra-ui/react";
import SomSvg from "../../../assets/svg/SomSvg";
import DefButton from "../../../components/ui/Buttons/DefButton";
import {IoLinkOutline} from "react-icons/io5";
import {useAppSelector} from "../../../config/hooks/redux";

const Others = () => {
  const {isLoading} = useAppSelector(s => s.PostCustomReducer)
  const onSubmit = (onClose: () => void) => {
  }
  return (
    <CustomerButton
      title={"Другое..."}
      bg={"#9747FF1A"}
      icon={<OthersSvg/>}
      onClick={onSubmit}
      total_price={1}
      disabled={true}
    >
      <>
        <InputComponent
          name={"title"}
          placeholder={"Ваш заказ"}
          leftIcon={<OthersSvg size={20}/>}
          type={"text"}
          required={true}
        />
        <InputComponent
          variant='flushed'
          name={"link"}
          placeholder={"Вставьте ссылку"}
          leftIcon={<IoLinkOutline/>}
          type={"url"}
          required={true}
        />
        <InputComponent
          variant='flushed'
          name={"price"}
          placeholder={"Укажите сумму"}
          leftIcon={<SomSvg/>}
          type={"text"}
          required={true}
        />
        <Box mt='38px'>
          {/*<DefButton isLoading={isLoading}>Разместить заказ 1с</DefButton>*/}
        </Box>
      </>
    </CustomerButton>
  );
};

export default Others;