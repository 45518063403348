import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IAccount, IPrice, IProfile} from "../../../models/user";

interface IProfileState {
  isError: null | string
  isLoading: boolean
  price: IPrice
  profile: IProfile
  accounts: IAccount[]
  all_accounts: IAccount[]
}

const initialState: IProfileState = {
  isError: null,
  isLoading: false,
  profile: {
    id: 0,
    role: -1,
    full_name: "",
    avatar: "",
    balance: 0,
    email: "",
    role_label: "",
    account_id: 0,
    applied_balance: 0
  },
  price: {
    follow: '1',
    comment: '1',
    like: '1',
  },
  accounts: [],
  all_accounts: [],
}

export const profileState = createSlice({
  name: 'get/profile',
  initialState,
  reducers: {
    setLoading(state) {
      state.isLoading = true
    },

    getProfile(state, action: PayloadAction<IProfile>) {
      state.isLoading = false
      state.profile = action.payload
    },

    getProfileError(state, action: PayloadAction<string>){
      state.isLoading = false
      state.isError = action.payload
    },
    getPrices(state, action: PayloadAction<IPrice>) {
      state.isLoading = false
      state.price = action.payload
    },
    getAccounts(state, action: PayloadAction<IAccount[]>) {
      state.isLoading = false
      state.accounts = action.payload.filter(el => el.is_checked).reverse()
      state.all_accounts = action.payload
    },

    setDefaultRole(state){
      state.profile.role = -1
    }
  }
})

export const ProfileReducer = profileState.reducer
export const {setDefaultRole} = profileState.actions
