import React, {useState} from 'react';
import {Box, Center, Flex, Link, Spinner} from "@chakra-ui/react";
import InputComponent from "../../ui/inputs/InputComponent";
import {CiMail} from "react-icons/ci";
import DefText from "../../ui/text/DefText";
import DefButton from "../../ui/Buttons/DefButton";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../config/hooks/redux";
import {ILoginValue} from "../../../models/user";
import {PiKeyLight} from "react-icons/pi";
import OtpInput from "../login/OtpInput";
import {onConfirmCode, onSendCode} from "../reducer/actions";


const ForgotPassword = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const {isLoading, isOpenOtpCode} = useAppSelector(s => s.LoginReducer)

  const [value, setValue] = useState<ILoginValue>({
    email: "",
    password: ""
  })


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({...value, [e.target.name]: e.target.value})
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(onSendCode(value.email))
  }

  const onConfirm = (code: string) => {
    dispatch(onConfirmCode({...value, code}, navigate))
  }
  return (
    <Box>
      <Flex justifyContent='center' mb='27px'>
        {isLoading && <Spinner/>}
        {!isLoading && <DefText fontSize='20px' fontWeight='500'>Почта</DefText>}
      </Flex>
      <form onSubmit={onSubmit}>
        <InputComponent
          handleChange={handleChange}
          leftIcon={<CiMail/>}
          value={value.email}
          name={"email"}
          type={"email"}
          placeholder={"Электронная почта "}
          required={true}
        />
        <InputComponent
          handleChange={handleChange}
          leftIcon={<PiKeyLight/>}
          name={"password"}
          value={value.password}
          placeholder={"Новый Пароль"}
          type={"password"}
          required={true}
        />
        <Center my={5} mt={10}>
          <Link
            onClick={() => navigate('/')}
            display='flex' color='#217CE6'
            alignItems='end' mx='auto'>Назад
          </Link>
          {isOpenOtpCode && <OtpInput onSubmit={onConfirm}/>}
        </Center>
        <DefButton disabled={isLoading}>Отправить</DefButton>
      </form>
    </Box>
  );
};

export default ForgotPassword;