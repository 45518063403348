import React, { Component, ReactNode } from "react";
import {Box, Container, Image, Text} from "@chakra-ui/react";

//local
import RobotNotFound from "../../assets/img/RobotNotFound.webp";

class NotFoundPage extends Component {
  render(): ReactNode {
    return (
      <Box bg='#202020'>
        <Container maxW='container.xl'>
          <Box
            minH="100vh"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            color="#FFFFFF"
            fontFamily="Roboto"
          >
            <Box display="flex" alignItems="center">
              <Text fontSize="8xl" fontWeight="400">
                4
              </Text>
              <Image
                maxW="70px"
                mx="5px"
                src={RobotNotFound}
                alt={`${RobotNotFound}_photo`}
              />
              <Text fontSize="8xl" fontWeight="400">
                4
              </Text>
            </Box>
            <Text textAlign="center" fontSize="3xl" fontWeight="400">
              NOT FOUND
            </Text>
          </Box>
        </Container>
      </Box>
    );
  }
}

export default NotFoundPage;
