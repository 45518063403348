import React, {FC, ReactNode, useEffect} from "react";
import { Box } from "@chakra-ui/react";
import {useLocation, useNavigate} from "react-router-dom";

//local
import {
  INTERFACE_WIDTH,
  NAVBAR_HEIGHT
} from "../../config/_variables";
import Navbar from "../../components/navbar/Navbar";
import {useAppSelector} from "../../config/hooks/redux";

type IProps = {
  children: ReactNode;
};

const InterfacePages: FC<IProps> = ({ children }) => {
  const {role} = useAppSelector(s => s.ProfileReducer.profile)
  const navigate = useNavigate()
  const { pathname } = useLocation();

  useEffect(() => {
    if (role !== -1){
      if (pathname.includes('customer') && !!role || pathname.includes('executor') && !role ) {
        navigate('/')
      }
    }
  }, [role])
  return (
    <Box
      maxW={INTERFACE_WIDTH}
      minHeight="100vh"
      mx="auto"
      bg={"#FFFFFF"}
      position="relative"
      pt='17px'
      pb={NAVBAR_HEIGHT}
    >
      {children}
       <Navbar />
    </Box>
  );
};

export default InterfacePages;
