import React, {useEffect} from 'react';
import {Box} from "@chakra-ui/react";
import {Route, Routes, useLocation} from "react-router-dom";
import {customerRoutes, executorRoutes, publicRoutes} from "./routes";
import ProtectedInterfacePage from "./ProtectedInterfacePage";
import {Toaster} from "react-hot-toast";
import NotFoundPage from "../pages/NotFoundPage";
import InterfacePages from "../pages/LayoutPages/InterfacePages";
import InterfaceFormsPages from "../pages/LayoutPages/InterfaceFormsPages";

const RoutesRender = () => {
  const {pathname} = useLocation()
  useEffect(() => window.scroll(0, 0), [pathname])
  return (
    <Box>
      <Toaster position="top-center" reverseOrder={true}/>
      <Routes>
        <Route>
          {publicRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={<InterfaceFormsPages>{route.element}</InterfaceFormsPages>}
            />
          ))}
        </Route>

        <Route element={<ProtectedInterfacePage/>}>
          {customerRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={<InterfacePages>{route.element}</InterfacePages>}
            />
          ))}
        </Route>

        <Route element={<ProtectedInterfacePage/>}>
          {executorRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={<InterfacePages>{route.element}</InterfacePages>}
            />
          ))}
        </Route>
        {/*<Route element={<ProtectAdminPage/>}>*/}
        {/*  {routesAdmin.map((route, idx) => (*/}
        {/*    <Route*/}
        {/*      key={idx}*/}
        {/*      path={route.path}*/}
        {/*      element={<Admin>{route.element}</Admin>}*/}
        {/*    />*/}
        {/*  ))}*/}
        {/*</Route>*/}
        <Route path="*" element={<NotFoundPage/>}/>
      </Routes>
    </Box>
  );
};

export default RoutesRender;