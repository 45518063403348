import {WHATSAPP_LINK} from "../../config/admin";
import PRIVATE_API from "../../api";
import toast from "react-hot-toast";

export const getMoney = async (value: any, setLoad: (a: boolean) => void, onClose: () => void) => {
  try {
    setLoad(true)
    await PRIVATE_API.post('task/payment_order/create/', value)
    setLoad(false)
    toast.success(`Запрос отправлен \n Просим ждать подтверждение`)
    onClose()
  }catch (e: any) {
    setLoad(false)
    toast.success(e.response?.data?.detail || "Произошла ошибка!")
  }
}

export const addMoney = (email: string) => {
  return `
${WHATSAPP_LINK}?text=${encodeURIComponent(`Приветствую!!\n`)}\n 
${encodeURIComponent(`\n`)}\n
 
${encodeURIComponent(`Я заказчик в платформе Demanbeg Like\n`)}\n
${encodeURIComponent(`\n`)}\n

${encodeURIComponent(`почта: ${email}\n`)}\n
${encodeURIComponent(`я хотел пополнить свой баланс чтобы создавать заказы.\n`)}\n 
${encodeURIComponent(`\n`)}\n

${encodeURIComponent(`Пришлите мне реквизиты я вам отправлю деньги и чек.\n`)}\n 
${encodeURIComponent(`\n`)}\n

Благодарю! \n`
}