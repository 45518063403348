import React from 'react';
import {Box} from "@chakra-ui/react";
import ProfileHeader from "../../components/profile-header";
import Balance from "../../components/balance";
import EmptyCard from "./emptyCard";
import {INTERFACE_PADDING_X} from "../../config/_variables";
import GrayContainer from "../../components/gray-container";
import ListOrders from "./list-orders";
import {useAppSelector} from "../../config/hooks/redux";
import {IAccount} from "../../models/user";

const Executor = () => {
  const {accounts, profile: {account_id}} = useAppSelector(s => s.ProfileReducer)
  const current_acc: IAccount | undefined = accounts.find(el => el.id === account_id)
  let offsetTop = 183
  return (
    <>
      <Box px={INTERFACE_PADDING_X}>
        <ProfileHeader/>
        <Balance page={"executor"}/>
      </Box>
      <GrayContainer offsetTop={offsetTop}>
        <>
          {!current_acc?.id && <EmptyCard/>}

          {!!current_acc?.id && <ListOrders/>}
        </>
      </GrayContainer>
    </>
  );
};

export default Executor;