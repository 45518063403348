import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface LoginSlice {
  isError: string | null;
  isLoading: boolean;
  isOpenAccount: boolean;
  isOpenOtpCode: boolean
}

const initialState: LoginSlice = {
  isError: null,
  isLoading: false,
  isOpenAccount: false,
  isOpenOtpCode: false
}

export const loginSlice = createSlice({
  name: 'login/user',
  initialState,
  reducers: {
    onLoading(state){
      state.isLoading = true
    },

    onLogin(state, action: PayloadAction<string | null>){
      state.isLoading = false
      state.isError = action.payload
    },
    onOpenAccount(state){
      state.isOpenAccount = true
    },
    onCloseAccount(state){
      state.isOpenAccount = false
    },

    onOpenOTPInput(state){
      state.isOpenOtpCode = true
    },
    onCloseOTPInput(state){
      state.isOpenOtpCode = false
    },
  }
})

export const LoginReducer = loginSlice.reducer
export const {onCloseAccount, onOpenAccount, onCloseOTPInput, onOpenOTPInput} = loginSlice.actions