import React from 'react';
import {Button, Flex, useDisclosure} from "@chakra-ui/react";
import Placement from "../../../components/ui/placement";
import DefText from "../../../components/ui/text/DefText";
import DefButton from "../../../components/ui/Buttons/DefButton";
import {useAppSelector} from "../../../config/hooks/redux";


interface Props {
  children: JSX.Element
  icon: JSX.Element
  bg: string
  title: string
  onClick: (onClose: () => void) => void
  total_price: number | string
  disabled?: boolean
}

const CustomerButton = ({children, title, bg, icon, onClick, total_price, disabled = false}: Props) => {
  const {isLoading} = useAppSelector(s => s.PostCustomReducer)
  const {onClose, isOpen, onOpen} = useDisclosure()
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onClick(onClose)
  }

  return (
    <>
      <Button
        variant='none'
        onClick={onOpen}
        flexDirection='column'
        alignItems='center'
        bg='#FFFFFF'
        isDisabled={disabled}
        rounded='16px'
        h='112px' py={4}
      >
        <Flex
          justifyContent='center' mb='10px'
          alignItems='center' bg={bg}
          w='50px' h='50px' rounded='12px'
        >{icon}</Flex>
        <DefText fontSize='14px' color="#3E3526">{title}</DefText>
      </Button>

      <Placement isOpen={isOpen} onClose={onClose} title={title} isLoading={isLoading}>
        <form onSubmit={onSubmit}>
          {children}
          <DefButton disabled={isLoading}>Разместить заказ {total_price}c</DefButton>
        </form>
      </Placement>
    </>
  );
};

export default CustomerButton;