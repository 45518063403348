import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.scss';
import { BrowserRouter as Router } from "react-router-dom";
import {ChakraProvider} from "@chakra-ui/react"
import App from './App';
import {Provider} from "react-redux";
import {store} from "./redux/store";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <ChakraProvider>
          <App/>
        </ChakraProvider>
      </Provider>
    </Router>
  </React.StrictMode>
)
