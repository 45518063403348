import React, {FC, useEffect} from 'react';
import {Box, Flex, Text} from "@chakra-ui/react";
import InstagramLogoSvg from "../../assets/svg/InstagramLogoSvg";
import DemanbegSvg from "../../assets/svg/DemanbegSvg";
import {INTERFACE_PADDING_X} from "../../config/_variables";
import {logout} from "../../components/forms/reducer/actions";
import {useAppDispatch} from "../../config/hooks/redux";
import {setDefaultRole} from "../../components/profile-header/reducer/slice";

interface Props {
  children: JSX.Element
}
const Home: FC<Props> = ({children}) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    logout()
    dispatch(setDefaultRole())
  }, [])
  return (
    <Box px={INTERFACE_PADDING_X}>
      <Box pt='11px' px='10px'>
        <Flex w='100%' justifyContent='center'>
          <InstagramLogoSvg/>
        </Flex>
        <Flex flexDirection='column' alignItems='center' mt='92px'>
          <DemanbegSvg/>
          <Text
            fontFamily='Arial' mt={2}
            fontSize='30.63px' color='#E11F26'
            fontWeight='400' lineHeight='30.63px'
            letterSpacing='83%'
          >LIKE</Text>
        </Flex>

        <Box mt='70.17px' w='100%'>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Home;