import React from 'react';

const DemanbegSvg = () => {
  return (
    <svg width="278" height="32" viewBox="0 0 278 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.75238 31.3062V0.524919H11.3557C13.7494 0.524919 15.5761 0.671896 16.8359 0.965851C18.5996 1.37179 20.1044 2.10667 21.3502 3.17051C22.9739 4.5423 24.1848 6.29903 24.9826 8.4407C25.7945 10.5684 26.2004 13.004 26.2004 15.7476C26.2004 18.0852 25.9275 20.1569 25.3816 21.9626C24.8357 23.7683 24.1358 25.2661 23.2819 26.4559C22.428 27.6317 21.4902 28.5626 20.4683 29.2485C19.4605 29.9204 18.2357 30.4313 16.7939 30.7812C15.3661 31.1312 13.7214 31.3062 11.8597 31.3062H0.75238ZM4.82575 27.6737H11.3977C13.4274 27.6737 15.0162 27.4848 16.164 27.1068C17.3258 26.7289 18.2497 26.197 18.9356 25.5111C19.9014 24.5452 20.6503 23.2504 21.1822 21.6267C21.7281 19.9889 22.0011 18.0082 22.0011 15.6846C22.0011 12.4651 21.4692 9.99446 20.4053 8.27272C19.3555 6.53699 18.0747 5.37517 16.5629 4.78726C15.4711 4.36733 13.7144 4.15736 11.2928 4.15736H4.82575V27.6737Z" fill="#2F2F2F"/>
      <path d="M37.0717 31.3062V0.524919H59.3283V4.15736H41.1451V13.5849H58.1735V17.1963H41.1451V27.6737H60.0422V31.3062H37.0717Z" fill="#2F2F2F"/>
      <path d="M70.7035 31.3062V0.524919H76.8345L84.1204 22.3195C84.7923 24.3492 85.2822 25.868 85.5902 26.8758C85.9401 25.756 86.486 24.1113 87.2279 21.9416L94.5978 0.524919H100.078V31.3062H96.1516V5.54314L87.2069 31.3062H83.5325L74.6299 5.10221V31.3062H70.7035Z" fill="#2F2F2F"/>
      <path d="M108.43 31.3062L120.251 0.524919H124.639L137.237 31.3062H132.597L129.006 21.9836H116.135L112.755 31.3062H108.43ZM117.311 18.6661H127.747L124.534 10.1414C123.554 7.55183 122.826 5.42416 122.35 3.75842C121.959 5.73211 121.406 7.69181 120.692 9.63751L117.311 18.6661Z" fill="#2F2F2F"/>
      <path d="M145.61 31.3062V0.524919H149.788L165.956 24.6922V0.524919H169.861V31.3062H165.683L149.515 7.1179V31.3062H145.61Z" fill="#2F2F2F"/>
      <path d="M181.719 31.3062V0.524919H193.267C195.619 0.524919 197.502 0.83987 198.916 1.46977C200.343 2.08568 201.456 3.04453 202.254 4.34633C203.066 5.63413 203.472 6.98492 203.472 8.3987C203.472 9.7145 203.115 10.9533 202.401 12.1151C201.687 13.2769 200.609 14.2148 199.168 14.9287C201.029 15.4746 202.457 16.4055 203.451 17.7213C204.459 19.0371 204.963 20.5908 204.963 22.3825C204.963 23.8243 204.655 25.1681 204.039 26.4139C203.437 27.6457 202.688 28.5976 201.792 29.2695C200.896 29.9414 199.769 30.4523 198.412 30.8022C197.068 31.1382 195.416 31.3062 193.456 31.3062H181.719ZM185.793 13.4589H192.449C194.254 13.4589 195.549 13.3399 196.333 13.102C197.369 12.794 198.146 12.2831 198.664 11.5692C199.196 10.8553 199.461 9.95946 199.461 8.88163C199.461 7.85979 199.217 6.96392 198.727 6.19404C198.237 5.41016 197.537 4.87825 196.627 4.59829C195.717 4.30433 194.156 4.15736 191.945 4.15736H185.793V13.4589ZM185.793 27.6737H193.456C194.772 27.6737 195.696 27.6247 196.228 27.5267C197.166 27.3588 197.95 27.0788 198.58 26.6869C199.21 26.2949 199.727 25.728 200.133 24.9861C200.539 24.2303 200.742 23.3624 200.742 22.3825C200.742 21.2347 200.448 20.2409 199.86 19.401C199.272 18.5471 198.454 17.9522 197.404 17.6163C196.368 17.2663 194.87 17.0914 192.91 17.0914H185.793V27.6737Z" fill="#2F2F2F"/>
      <path d="M215.813 31.3062V0.524919H238.069V4.15736H219.886V13.5849H236.915V17.1963H219.886V27.6737H238.783V31.3062H215.813Z" fill="#2F2F2F"/>
      <path d="M263.757 17.9293H277.048L277.013 15.6006V26.2945C277.013 26.7488 276.783 27.1723 276.403 27.4222C274.223 28.8584 272.771 29.8897 270.819 30.6343C268.692 31.4321 266.508 31.8311 264.268 31.8311C261.245 31.8311 258.494 31.1872 256.017 29.8994C253.553 28.5976 251.691 26.7219 250.431 24.2722C249.172 21.8226 248.542 19.0861 248.542 16.0625C248.542 13.067 249.165 10.2744 250.41 7.68481C251.67 5.08121 253.476 3.14951 255.828 1.88971C258.179 0.629903 260.888 0 263.953 0C266.179 0 268.188 0.363944 269.979 1.09183C271.785 1.80572 273.199 2.80657 274.221 4.09437C275.243 5.38217 276.02 7.06191 276.551 9.13359L272.877 10.1414C272.415 8.57368 271.841 7.34187 271.155 6.446C270.469 5.55014 269.49 4.83625 268.216 4.30433C266.942 3.75842 265.528 3.48546 263.974 3.48546C262.113 3.48546 260.503 3.77242 259.145 4.34633C257.787 4.90624 256.689 5.64813 255.849 6.57198C255.023 7.49584 254.379 8.51068 253.917 9.61651C253.133 11.5202 252.741 13.5849 252.741 15.8106C252.741 18.5541 253.21 20.8498 254.148 22.6975C255.1 24.5452 256.479 25.917 258.284 26.8129C260.09 27.7087 262.008 28.1567 264.037 28.1567C265.801 28.1567 267.523 27.8207 269.203 27.1488C270.882 26.4629 272.156 25.735 273.024 24.9651V17.9293H263.757Z" fill="#2F2F2F"/>
    </svg>

  );
};

export default DemanbegSvg;