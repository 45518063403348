import React from 'react';

const CommentSvg = ({size=26}: {size?: number}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.4166 2.63257H7.58329C4.33329 2.63257 2.16663 4.79924 2.16663 8.04924V14.5492C2.16663 17.7992 4.33329 19.9659 7.58329 19.9659V22.2734C7.58329 23.1401 8.54746 23.6601 9.26246 23.1726L14.0833 19.9659H18.4166C21.6666 19.9659 23.8333 17.7992 23.8333 14.5492V8.04924C23.8333 4.79924 21.6666 2.63257 18.4166 2.63257ZM13 15.8167C12.545 15.8167 12.1875 15.4484 12.1875 15.0042C12.1875 14.5601 12.545 14.1917 13 14.1917C13.455 14.1917 13.8125 14.5601 13.8125 15.0042C13.8125 15.4484 13.455 15.8167 13 15.8167ZM14.365 11.3209C13.9425 11.6026 13.8125 11.7867 13.8125 12.0901V12.3176C13.8125 12.7617 13.4441 13.1301 13 13.1301C12.5558 13.1301 12.1875 12.7617 12.1875 12.3176V12.0901C12.1875 10.8334 13.1083 10.2159 13.455 9.97757C13.8558 9.70674 13.9858 9.52257 13.9858 9.2409C13.9858 8.69923 13.5416 8.25507 13 8.25507C12.4583 8.25507 12.0141 8.69923 12.0141 9.2409C12.0141 9.68507 11.6458 10.0534 11.2016 10.0534C10.7575 10.0534 10.3891 9.68507 10.3891 9.2409C10.3891 7.80007 11.5591 6.63007 13 6.63007C14.4408 6.63007 15.6108 7.80007 15.6108 9.2409C15.6108 10.4759 14.7008 11.0934 14.365 11.3209Z" fill="#4BD340"/>
    </svg>
  );
};

export default CommentSvg;