import {AppDispatch} from "../../../redux/store";
import {ILoginValue, IProfile} from "../../../models/user";
import {loginSlice, onCloseOTPInput, onOpenOTPInput} from "./slice";
import PRIVATE_API, {PUBLIC_API} from "../../../api";
import {toLocalStorage} from "../../../config/helpers";
import {toastCreator} from "../../../config/toast";
import toast from "react-hot-toast";
import {editProfile, getProfile} from "../../profile-header/reducer/actions";

export const onLogin = (value: ILoginValue, role: number, isLogin: boolean, navigate: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loginSlice.actions.onLoading())
    const {data} = await PUBLIC_API.post("account/token/", value)
    saveToken(data)
    const {data: {role: roleId}} = await PRIVATE_API.get<IProfile>("account/profile/")

    if (!roleId) navigate(`/customer/create`);
    else navigate(`/executor/orders`);
    toastCreator("success", "Добро пожаловать!");

    dispatch(loginSlice.actions.onLogin(null));
  } catch (e: any) {
    toast.error(e.response?.data?.email || e.response?.data?.detail || "Произошла ошибка");
    dispatch(loginSlice.actions.onLogin(e.response?.data?.detail));
  }
}

export const onSignUp = (value: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loginSlice.actions.onLoading())
    await PUBLIC_API.post("account/signup/", {...value})
    dispatch(onOpenOTPInput())
    dispatch(loginSlice.actions.onLogin(null));
  } catch (e: any) {
    toast.error(e.response?.data?.email || e.response?.data?.detail || "Произошла ошибка");
    dispatch(loginSlice.actions.onLogin(e.response?.data?.detail));
  }
}

export const onSendCode = (email: string) => async (dispatch: AppDispatch) => {
  dispatch(loginSlice.actions.onLoading())
  PUBLIC_API.post("account/code/send/", {email})
    .then(() => {
      dispatch(onOpenOTPInput())
      dispatch(loginSlice.actions.onLogin(null));
    })
    .catch((e: any) => {
      toast.error(e.response?.data?.email || e.response?.data?.detail || "Произошла ошибка");
      dispatch(loginSlice.actions.onLogin(e.response?.data?.detail));
    })
}


export const onConfirmCode = (value: any, navigate: any, onClose?: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loginSlice.actions.onLoading())
    const {data} = value.password ?
      await PUBLIC_API.post("account/password/reset/", {...value}) :
      await PUBLIC_API.post("account/code/verify/", {...value})
    saveToken(data)
    if (onClose){
      dispatch(getProfile())
      onClose()
      toastCreator("success", "Данные обновлены");
      dispatch(loginSlice.actions.onLogin(null));
      dispatch(onCloseOTPInput())
      return;
    }
    const {data: {role: roleId}} = await PRIVATE_API.get<IProfile>("account/profile/")

    if (!roleId) navigate(`/customer/create`);
    else navigate(`/executor/orders`);
    toastCreator("success", "Добро пожаловать!");
    dispatch(onCloseOTPInput())
    dispatch(loginSlice.actions.onLogin(null));
  } catch (e: any) {
    toast.error(e.response?.data?.email || e.response?.data?.detail || "Произошла ошибка");
    dispatch(loginSlice.actions.onLogin(e.response?.data?.detail));
  }
}

export const saveToken = (data: any) => {
  toLocalStorage("access", data.access);
  toLocalStorage("refresh", data.refresh);
}

export const logout = () => {
  sessionStorage.removeItem("access");
  sessionStorage.removeItem("refresh");
};