import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ICommentOrder, IFollowOrder, ILikeOrder} from "../../../models/tasks";

export interface IOrderState {
  follow: IFollowOrder[]
  likes: ILikeOrder[]
  comments: ICommentOrder[]
  // others: any[]
}

interface ICustomerState {
  isError: null | string
  isLoading: boolean
  orders: IOrderState
}

const initialState: ICustomerState = {
  isError: null,
  isLoading: false,
  orders: {
    follow: [],
    comments: [],
    likes: [],
    // others: [],
  },
}

export const ordersSLice = createSlice({
  name: 'get/orders',
  initialState,
  reducers: {
    setLoading(state) {
      state.isLoading = true
    },
    getOrdersSuccess(state, action: PayloadAction<IOrderState>) {
      state.isLoading = false
      state.isError = null
      state.orders = action.payload
    },

    getOrdersError(state, action: PayloadAction<null | string>) {
      state.isLoading = false
      state.isError = action.payload
    }
  }
})

export const OrderReducer = ordersSLice.reducer
