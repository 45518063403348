import React, {useEffect, useState} from 'react';
import {Box, Center, Flex, Link, useDisclosure} from "@chakra-ui/react";
import InputComponent from "../../ui/inputs/InputComponent";
import {CiMail} from "react-icons/ci";
import DefButton from "../../ui/Buttons/DefButton";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../config/hooks/redux";
import {ILoginValue, IProfile} from "../../../models/user";
import {PiKeyLight} from "react-icons/pi";
import OtpInput from "../login/OtpInput";
import {onConfirmCode, onSendCode} from "../reducer/actions";
import Placement from "../../ui/placement";
import {editProfile} from "../../profile-header/reducer/actions";
import PRIVATE_API from "../../../api";
import {loginSlice} from "../reducer/slice";
import toast from "react-hot-toast";


const ChangePassword = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {isOpen, onOpen, onClose} = useDisclosure()
  const {isLoading, isOpenOtpCode} = useAppSelector(s => s.LoginReducer)
  const {email, id} = useAppSelector(s => s.ProfileReducer.profile)

  const [value, setValue] = useState<ILoginValue>({
    email: "",
    password: ""
  })


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({...value, [e.target.name]: e.target.value})
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(loginSlice.actions.onLoading())
    PRIVATE_API.patch<IProfile>(`account/profile/update/${id}/`, {email: value.email})
      .then(({data}) => dispatch(onSendCode(data.email)))
      .catch((e) => {
        toast.error(e.response?.data?.email || e.response?.data?.detail || "Произошла ошибка");
        dispatch(loginSlice.actions.onLogin(e.response?.data?.detail));
      })

  }
  const onConfirm = (code: string) => {
    dispatch(onConfirmCode({...value, code}, navigate, onClose))
  }
  useEffect(() => {
    setValue({...value, email})
  }, [email])
  return (
    <Box>
      <Box mt='24px'>
        <Link
          onClick={onOpen}
          fontSize='16px' fontWeight='400' py='8px'
          fontFamily={"Roboto"}
          lineHeight='21px' color='#0297F6'
        >Изменить логин и пароль</Link>
      </Box>
      <Placement isOpen={isOpen} onClose={onClose} title={"Добавить аккаунт"} isLoading={isLoading}>
        <form onSubmit={onSubmit}>
          <InputComponent
            handleChange={handleChange}
            leftIcon={<CiMail/>}
            value={value.email}
            name={"email"}
            type={"email"}
            placeholder={"Электронная почта "}
            required={true}
          />
          <InputComponent
            handleChange={handleChange}
            leftIcon={<PiKeyLight/>}
            name={"password"}
            value={value.password}
            placeholder={"Новый Пароль"}
            type={"password"}
            required={true}
          />

          {isOpenOtpCode && <Center><OtpInput onSubmit={onConfirm}/></Center>}
          <Box mt='34px'>
            <DefButton disabled={isLoading}>{'Обновить'}</DefButton>
          </Box>
        </form>
      </Placement>
    </Box>
  );
};

export default ChangePassword;