import React, {useState} from 'react';
import {Box, Flex, Heading, useDisclosure} from "@chakra-ui/react";
import {GoPlus} from "react-icons/go";
import Placement from "../../ui/placement";
import InputComponent from "../../ui/inputs/InputComponent";
import {PiInstagramLogoLight, PiKeyLight} from "react-icons/pi";
import DefButton from "../../ui/Buttons/DefButton";
import {useAppDispatch, useAppSelector} from "../../../config/hooks/redux";
import {addAccount} from "../reducer/actions";

const AddAccount = () => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const dispatch = useAppDispatch()
  const {role} = useAppSelector(s => s.ProfileReducer.profile)
  const {isLoading} = useAppSelector(s => s.LoginReducer)
  const [value, setValue] = useState({
    account: "",
    password: ""
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({...value, [e.target.name]: e.target.value.trim().toLowerCase()})
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(addAccount(value, role, onClose))
  }
  return (
    <>
      <Flex
        onClick={onOpen}
        alignItems='center' mb='26px'
        cursor='pointer' _active={{opacity: .8}}
      >
        <Flex
          w='44px' h='44px' rounded='50%' bg='#E6E6E6'
          color='#202020' fontSize='2rem'
          justifyContent='center' alignItems='center'
        ><GoPlus/></Flex>
        <Heading
          ml='10px'
          color='#202020'
          fontFamily='Roboto'
          fontWeight='500'
          lineHeight='21px'
          fontSize='18px'
        >Добавить аккаунт</Heading>
      </Flex>

      <Placement isOpen={isOpen} onClose={onClose} title={"Добавить аккаунт"} isLoading={isLoading}>
        <form onSubmit={onSubmit}>
          <InputComponent
            handleChange={handleChange}
            leftIcon={<PiInstagramLogoLight/>}
            value={value.account}
            name={"account"}
            placeholder={"Никнейм/Логин"}
            required={true}
          />
          {!role && <InputComponent
            handleChange={handleChange}
            value={value.password}
            leftIcon={<PiKeyLight/>}
            name={"password"}
            type={"password"}
            placeholder={"Пароль"}
            required={true}
          />}

          <Box mt='34px'>
            <DefButton disabled={isLoading}>{!!role ? 'Добавить аккаунт через WhatsApp'  : 'Войти'}</DefButton>
          </Box>
        </form>
      </Placement>
    </>
  );
};

export default AddAccount;