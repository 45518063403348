import React, {useEffect, useState} from 'react';
import {
  Box, Center, Flex, Link, Progress
} from "@chakra-ui/react";
import {INTERFACE_WIDTH} from "../../config/_variables";
import TitleComponents from "../../components/ui/text/TitleComponent";
import InputComponent from "../../components/ui/inputs/InputComponent";
import {useAppDispatch, useAppSelector} from "../../config/hooks/redux";
import {useNavigate} from "react-router-dom";
import {editProfile} from "../../components/profile-header/reducer/actions";
import AvatarLoad from "./AvatarLoad";
import DefButton from "../../components/ui/Buttons/DefButton";
import InstagramAccount from "./InstagramAccount";
import ChangePassword from "../../components/forms/change-password";
import {MOTION_WEB_LINK} from "../../config/admin";

const Profile = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {full_name, id, role} = useAppSelector(s => s.ProfileReducer.profile)
  const {isLoading} = useAppSelector(s => s.LoginReducer)
  const [value, setValue] = useState({
    full_name,
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({...value, [e.target.name]: e.target.value})
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(editProfile({...value}, id, () => {
    }))
  }

  useEffect(() => {
    setValue({full_name})
  }, [full_name])
  return (
    <Box position='fixed' bottom='0' left='0' h='100%' w='100%'>
      <Flex
        alignItems='end'
        maxW={INTERFACE_WIDTH}
        mx="auto" h='100%' bg='#202020'
        pt='19px'
      >
        <Box
          h="100%"
          w={'100%'}
          pt='14px'
          borderTopRadius="34px"
          bg={"#F5F5F5"} pb='10px'
        >
          <TitleComponents>Профиль</TitleComponents>
          {isLoading && <Progress size='xs' bg='none' isIndeterminate/>}
          <Flex flexDirection='column' justifyContent='space-between' h='100%' pb={'100px'}>
            <Box className="unscroll" px={4} mt={isLoading ? '27px' : '31px'} overflow='auto'>

              <Flex justifyContent='center' pb='15px' w='100%'>
                <AvatarLoad/>
              </Flex>
              <form onSubmit={onSubmit}>
                <InputComponent
                  variant='flushed'
                  name={"full_name"}
                  handleChange={handleChange}
                  value={value.full_name}
                  placeholder={"Имя пользователя"}
                  type={"text"}
                  required={true}
                />
                <DefButton>Сохранить</DefButton>
              </form>
              {!!role && <InstagramAccount/>}
              <ChangePassword/>
              <Box mt='24px'>
                <Link
                  onClick={() => navigate('/')}
                  fontSize='16px' fontWeight='400' py='8px'
                  fontFamily={"Roboto"}
                  lineHeight='21px' color='#0297F6'
                >Выйти </Link>
              </Box>
            </Box>

            <Center>
              <Link
                href={MOTION_WEB_LINK} target="_blank"
                fontFamily='Roboto' lineHeight='21px' fontWeight='400'
                fontSize='12px' color='#202020'
              >Платформа разработана в Motion Web LLC↗</Link>
            </Center>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default Profile;