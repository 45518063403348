

export const blue_color = "#0297F6"
export const green_color = "#4BD340"
export const red_color = "#FE6D72"
export const purple_color = "#9747FF"

interface IOrderColors {
  [key: number]: string,
}

export const order_status: IOrderColors = {
  0: green_color,
  1: blue_color,
  2: green_color,
  3: red_color,
}