import React, {FC, ReactNode} from "react";
import { Box } from "@chakra-ui/react";

//local
import {
  INTERFACE_WIDTH,
  NAVBAR_HEIGHT,
} from "../../config/_variables";

type IProps = {
  children: ReactNode;
};

const InterfaceFormsPages: FC<IProps> = ({ children }) => {
  return (
    <Box
      maxW={INTERFACE_WIDTH}
      minHeight="100vh"
      mx="auto"
      bg={"#FFFFFF"}
      position="relative"
      pt='17px'
      pb={NAVBAR_HEIGHT}
    >
      {children}
    </Box>
  );
};

export default InterfaceFormsPages;
