import React from 'react';
import {Box} from "@chakra-ui/react";
import {INTERFACE_PADDING_X} from "../../../config/_variables";
import ProfileHeader from "../../../components/profile-header";
import GrayContainer from "../../../components/gray-container";
import {useAppSelector} from "../../../config/hooks/redux";
import ListOrders from "../../executor/list-orders";

const MyOrders = () => {
  const offsetTop = 99
  const {role} = useAppSelector(s => s.ProfileReducer.profile)
  return (
    <Box h='100%'>
      <Box px={INTERFACE_PADDING_X}>
        <ProfileHeader/>
      </Box>
      <GrayContainer offsetTop={offsetTop}>
        <ListOrders/>
      </GrayContainer>
    </Box>
  );
};

export default MyOrders;