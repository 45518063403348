import React, {useEffect, useState} from 'react';
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {Avatar, Box, Flex, SimpleGrid, Text} from "@chakra-ui/react";
import {INTERFACE_WIDTH, NAVBAR_HEIGHT} from "../../config/_variables";
import HomeSvg from "../../assets/svg/HomeSvg";
import AddPageSvg from "../../assets/svg/AddPageSvg";
import {useAppDispatch, useAppSelector} from "../../config/hooks/redux";
import {useAuthUser} from "../../config/hooks/userAccess";
import {getProfile} from "../profile-header/reducer/actions";
import Loader from "../Loader";
import {getCustoms} from "../../pages/executor/reducer/actions";



const Navbar = () => {

  const {isLoading, profile: {avatar}} = useAppSelector(s => s.ProfileReducer)

  const navbar_customer = [
    {
      icon: <HomeSvg/>,
      path: "/customer/my-orders",
    },
    {
      icon: <AddPageSvg/>,
      path: "/customer/create",
    },
    {
      icon:  <Avatar width={7} h={7} cursor='pointer' src={avatar}/>,
      path: "/customer/profile",
    }
  ]

  const navbar_executor = [
    {
      icon: <HomeSvg/>,
      path: "/executor/orders",
    },
    {
      icon:  <Avatar width={7} h={7} cursor='pointer' src={avatar}/>,
      path: "/executor/profile",
    }
  ]


  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [navbar, setNavbar] = useState(navbar_customer)
  const isAuth = useAuthUser()
  const {pathname} = useLocation()
  useEffect(() => {
    if (isAuth) {
      dispatch(getProfile());
      dispatch(getCustoms())
    }
  }, [isAuth])


  useEffect(() => {
    if (pathname.includes('customer')){
      setNavbar(navbar_customer)
    }else {
      setNavbar(navbar_executor)
    }
  },[pathname])
  return (
    <Flex position='fixed' zIndex='20' bottom='0' left='0' right='0'>
      {isLoading && <Loader/>}
      <Box mx='auto'
           w={INTERFACE_WIDTH}
           h={NAVBAR_HEIGHT} bg={'#202020'}
           borderTop='1px solid #2E2E2E'>
        <SimpleGrid
          columns={navbar.length}
          className='navbar' h={'100%'}
        >
          {navbar.map((nav, idx) => (
            <Flex
              key={idx}
              onClick={() => navigate(nav.path)}
              cursor='pointer'
              w='100%' h={'100%'}
              justifyContent='center'
              alignItems='center' pt='6px' pb={3}
              borderRight={pathname.includes('executor') ? "1px solid #FFFFFF" : 'none'}
            >
              <NavLink
                to={nav.path} className='navbar--link'
                style={{color: "#FFFFFF"}}
              >
                <Flex className='nav-icon' h='26px' justifyContent='center'
                      alignItems='center'>{nav.icon}</Flex>
              </NavLink>
            </Flex>
          ))}
        </SimpleGrid>
      </Box>
    </Flex>
  );
};

export default Navbar;