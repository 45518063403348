import {RouteProps} from "react-router-dom";
import Customer from "../pages/customer";
import Executor from "../pages/executor";
import Home from "../pages/home";
import Login from "../components/forms/login";
import Profile from "../pages/profile";
import MyOrders from "../pages/customer/my-orders";
import ForgotPassword from "../components/forms/forgot-password";


export const publicRoutes: RouteProps[] = [
  {
    path: '/',
    element: <Home><Login isLogin={true}/></Home>
  },
  {
    path: '/sign-up',
    element: <Home><Login isLogin={false}/></Home>
  },
  {
    path: '/forgot-password',
    element: <Home><ForgotPassword/></Home>
  },
]

export const customerRoutes: RouteProps[] = [
  {
    path: "/customer/create",
    element: <Customer/>
  },
  {
    path: "/customer/my-orders",
    element: <MyOrders/>
  },
  {
    path: "/customer/profile",
    element: <Profile/>
  },

]


export const executorRoutes: RouteProps[] = [
  {
    path: "/executor/orders",
    element: <Executor/>
  },
  {
    path: "/executor/profile",
    element: <Profile/>
  },

]