import {AppDispatch} from "../../../redux/store";
import toast from "react-hot-toast";
import {postCustomSLice} from "./slice";
import PRIVATE_API from "../../../api";
import {IFollowValue, ILikeValue, ICommentValue} from "../../../models/orders";
import {getProfile} from "../../../components/profile-header/reducer/actions";
import {getCustoms} from "../../executor/reducer/actions";


// Создание

export const createFollow = (value: IFollowValue, onClose: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(postCustomSLice.actions.setLoading())
    const {data} = await PRIVATE_API.post("task/follow/create/", value)

    dispatch(postCustomSLice.actions.addedCustom(null))
    dispatch(getProfile())
    dispatch(getCustoms())
    toast.success("ваш заказ создан");
    onClose()
  } catch (e: any) {
    toast.error(e.response?.data?.total || "Произошла ошибка");
    dispatch(postCustomSLice.actions.addedCustom(e.response?.data?.total));
  }
}

export const createLike = (value: ILikeValue, onClose: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(postCustomSLice.actions.setLoading())
    const {data} = await PRIVATE_API.post("task/like/create/", value)

    dispatch(postCustomSLice.actions.addedCustom(null))
    dispatch(getProfile())
    dispatch(getCustoms())
    toast.success("ваш заказ создан");
    onClose()
  } catch (e: any) {
    toast.error(e.response?.data?.total || "Произошла ошибка");
    dispatch(postCustomSLice.actions.addedCustom(e.response?.data?.total));
  }
}

export const createComment = (value: ICommentValue, onClose: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(postCustomSLice.actions.setLoading())
    const {data} = await PRIVATE_API.post("task/comment/create/", value)

    dispatch(postCustomSLice.actions.addedCustom(null))
    dispatch(getProfile())
    dispatch(getCustoms())
    toast.success("your order has been created");
    onClose()
  } catch (e: any) {
    toast.error(e.response?.data?.total || "Произошла ошибка");
    dispatch(postCustomSLice.actions.addedCustom(e.response?.data?.total));
  }
}


// Проверка

export type TypeOrders = 'like' | 'follow' | 'comment'

export const executeOrders = (
  executor: number,
  id: number,
  setLoad: (a: boolean) => void,
  order_type: TypeOrders,
  toInstagram: () => void
) => async (dispatch: AppDispatch) => {
  try {
    setLoad(true)
    if (order_type === 'follow') {
      await PRIVATE_API.post(`task/follow/apply/`, {executor, follow: id})
    } else if (order_type === 'like') {
      await PRIVATE_API.post(`task/like/apply/`, {executor, like: id})
    } else if (order_type === 'comment') {
      await PRIVATE_API.post(`task/comment/apply/`, {executor, comment: id})
    }

    setLoad(false)
    dispatch(getCustoms())
    toInstagram()
  } catch (e: any) {
    toast.error(e.response?.data?.executor || e.response?.data || "Произошла ошибка");
    setLoad(false);
  }
}
