import {AppDispatch} from "../../../redux/store";
import PRIVATE_API from "../../../api";
import {ordersSLice} from "./slice";
import {ICommentOrder, IFollowOrder, ILikeOrder} from "../../../models/tasks";


export const getCustoms = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(ordersSLice.actions.setLoading())
    const {data: follow} = await PRIVATE_API.get<IFollowOrder[]>("task/follow/")
    const {data: likes} = await PRIVATE_API.get<ILikeOrder[]>("task/like/")
    const {data: comments} = await PRIVATE_API.get<ICommentOrder[]>("task/comment/")
    dispatch(ordersSLice.actions.getOrdersSuccess({
      follow: follow.filter(el => el.applied_quantity < el.quantity),
      likes: likes.filter(el => el.applied_quantity < el.quantity),
      comments: comments.filter(el => el.applied_quantity < el.quantity)
    }))
  } catch (e: any) {
    dispatch(ordersSLice.actions.getOrdersError(e.response?.data?.total));
  }
}


