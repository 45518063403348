import React from 'react';
import {Divider, Flex} from "@chakra-ui/react";

interface Props {
  children: JSX.Element
  colorLine: string
  hidden?: boolean
}

const OrderCard = ({colorLine, children, hidden= false}: Props) => {

  return (
    <Flex
      display={hidden ? 'none' : 'flex'}
      position='relative' p={4}
      rounded='16px' bg='#FFFFFF'
      overflow='hidden' mb='8px'
    >
      <Divider orientation="vertical" bg={colorLine} h='100%' w='3px' position='absolute' left='0' top='0' bottom='0'/>
      {children}
    </Flex>
  );
};

export default OrderCard;