import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ICustomerState {
  isError: null | string
  isLoading: boolean
}

const initialState: ICustomerState =  {
  isError: null,
  isLoading: false
}

export const postCustomSLice = createSlice({
  name: 'post/custom',
  initialState,
  reducers: {
    setLoading(state){
      state.isLoading = true
    },

    addedCustom(state, action: PayloadAction<null | string>){
      state.isLoading = false
      state.isError = action.payload
    }
  }
})

export const PostCustomReducer = postCustomSLice.reducer
