import React, {useState} from 'react';
import CustomerButton from "../CustomerButton";
import CommentSvg from "../../../assets/svg/CommentSvg";
import {Box, Flex} from "@chakra-ui/react";
import SomSvg from "../../../assets/svg/SomSvg";
import DefText from "../../../components/ui/text/DefText";
import InputComponent from "../../../components/ui/inputs/InputComponent";
import {useAppDispatch, useAppSelector} from "../../../config/hooks/redux";
import {ICommentValue} from "../../../models/orders";
import {createComment} from "../reducer/actions";
import toast from "react-hot-toast";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";

const Comments = () => {
  const {comment: price} = useAppSelector(s => s.ProfileReducer.price)
  const dispatch = useAppDispatch()
  const [value, setValue] = useState<ICommentValue>({
    commenttexts: [''],
    price,
    url: '',
    quantity: '',
    total: ''
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({...value, [e.target.name]: e.target.value})
  }

  const handleChangeComment = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({...value, commenttexts: value.commenttexts.fill(e.target.value, index, index + 1)})
  }

  const addInput = () => {
    if (value.commenttexts.every(el => !!el.trim())) {
      setValue({...value, commenttexts: [...value.commenttexts, ""]})
    }
  }

  const removeInput = (index: number) => {
    setValue({...value, commenttexts: value.commenttexts.filter((_, idx) => idx !== index)})
  }

  const handleClick = (index: number) => {
    if (index === value.commenttexts.length - 1){
      addInput()
    }else {
      removeInput(index)
    }
  }

  const onSubmit = (onClose: () => void) => {
    if (value.url.trim().includes('https://www.instagram.com/')) {
      dispatch(createComment({...value, total: +price * +value.quantity}, onClose))
    } else {
      toast.error('Эта  ссылка не принадлежит инстаграму')
    }
  }
  return (
    <CustomerButton
      title={"Комментарий"}
      bg={"#4BD3401A"}
      icon={<CommentSvg/>}
      onClick={onSubmit}
      total_price={+price * +value.quantity || price}
    >
      <>
        <InputComponent
          handleChange={handleChange}
          name={"url"}
          placeholder={"Ссылка на пост"}
          value={value.url}
          leftIcon={<CommentSvg size={20}/>}
          type={"url"}
          required={true}
        />
        <InputComponent
          handleChange={handleChange}
          value={value.quantity}
          name={"quantity"}
          placeholder={"Количество комментариев"}
          leftIcon={<CommentSvg size={20}/>}
          type={"number"}
          required={true}
        />
        {value.commenttexts.map((el, idx) => (
          <InputComponent
            key={idx}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeComment(idx, e)}
            onCLick={() => handleClick(idx)}
            value={el}
            variant='flushed'
            name={"comment"}
            placeholder={"Что написать"}
            type={"text"}
            required={true}
            rightIcon={
              idx === value.commenttexts.length - 1 ?
            <Box color='#0297F6'><AiOutlinePlus/></Box> :  <Box color='#FE6D72'><AiOutlineMinus/></Box>
          }
          />
        ))}
        <Box mt='38px'>
          <Flex alignItems='center' justifyContent='center' mb='18px'>
            <SomSvg/>
            <Box ml={3}><DefText fontSize='14px' children={`Комментарий по ${price}с`}/></Box>
          </Flex>
          {/*<DefButton isLoading={isLoading}>Разместить заказ {+price * +value.quantity || price}с</DefButton>*/}
        </Box>
      </>
    </CustomerButton>
  );
};

export default Comments;