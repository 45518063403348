import React from 'react';

const AddPageSvg = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" y="0.75" width="20.5" height="20.5" rx="6.25" stroke="white" strokeWidth="1.5"/>
      <path d="M10.9998 5.6665V16.3332M5.6665 10.9998H16.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default AddPageSvg;