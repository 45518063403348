import React from 'react';

const HomeSvg = () => {
  return (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.39588 2.83992L3.78129 7.03992C2.84379 7.73992 2.08337 9.22992 2.08337 10.3599V17.7699C2.08337 20.0899 4.05212 21.9899 6.46879 21.9899H18.5313C20.948 21.9899 22.9167 20.0899 22.9167 17.7799V10.4999C22.9167 9.28992 22.073 7.73992 21.0417 7.04992L14.6042 2.71992C13.1459 1.73992 10.8021 1.78992 9.39588 2.83992Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.5 17.99V14.99" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);
};

export default HomeSvg;