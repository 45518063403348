import React, {useEffect, useState} from 'react';
import {Box, Link, useDisclosure} from "@chakra-ui/react";
import {useAppDispatch, useAppSelector} from "../../../config/hooks/redux";
import {editAccount} from "../../../components/profile-header/reducer/actions";
import InputComponent from "../../../components/ui/inputs/InputComponent";
import {PiInstagramLogoLight, PiKeyLight} from "react-icons/pi";
import DefButton from "../../../components/ui/Buttons/DefButton";
import Placement from "../../../components/ui/placement";
import {IAccount} from "../../../models/user";
import toast from "react-hot-toast";

const InstagramAccount = () => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const dispatch = useAppDispatch()
  const {isLoading} = useAppSelector(s => s.LoginReducer)
  const {profile: {role, account_id}, accounts} = useAppSelector(s => s.ProfileReducer)

  const my_account: IAccount | undefined = accounts.find(el => el.id === account_id)
  const [value, setValue] = useState({
    account: my_account?.account,
    password: my_account?.password
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({...value, [e.target.name]: e.target.value})
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (my_account?.id) {
      dispatch(editAccount(value, my_account.id, onClose))
    }else {
      toast.error("Необхадмио связать инстаграм аккаунт")
    }
  }

  useEffect(() => {
    setValue({
      ...value,
      account: my_account?.account,
      password: my_account?.password
    })
  }, [my_account])
  return (
    <>
      {my_account?.id && <Box mt='24px' onClick={onOpen}>
        <Link
          fontSize='16px' fontWeight='400' py='8px'
          fontFamily={"Roboto"}
          lineHeight='21px' color='#0297F6'
        >Изменить данные инстаграма </Link>
      </Box>}
      <Placement isOpen={isOpen} onClose={onClose} title={"Добавить аккаунт"} isLoading={isLoading}>
        <form onSubmit={onSubmit}>
          <InputComponent
            handleChange={handleChange}
            leftIcon={<PiInstagramLogoLight/>}
            value={value.account}
            name={"account"}
            placeholder={"Никнейм/Логин"}
            required={true}
          />
          {!role && <InputComponent
            handleChange={handleChange}
            value={value.password}
            leftIcon={<PiKeyLight/>}
            name={"password"}
            type={"password"}
            placeholder={"Пароль"}
            required={true}
          />}

          <Box mt='34px'>
            <DefButton disabled={isLoading}>{'Сохранить'}</DefButton>
          </Box>
        </form>
      </Placement>
    </>
  );
};

export default InstagramAccount;