import React from 'react';
import {Box, SimpleGrid} from "@chakra-ui/react";
import ProfileHeader from "../../components/profile-header";
import Balance from "../../components/balance";
import {INTERFACE_PADDING_X, NAVBAR_HEIGHT} from "../../config/_variables";
import Followers from "./followers";
import Likes from "./likes";
import Comments from "./comments";
import Others from "./others";
import GrayContainer from "../../components/gray-container";

const Customer = () => {
  const offsetTop = 285
  return (
    <Box h='100%' >
      <Box px={INTERFACE_PADDING_X}>
        <ProfileHeader/>
        <Balance page={"customer"}/>
      </Box>
      <GrayContainer offsetTop={offsetTop}>
        <SimpleGrid columns={2} spacing='8px'>
          <Followers/>
          <Likes/>
          <Comments/>
          <Others/>
        </SimpleGrid>
      </GrayContainer>
    </Box>
  );
};

export default Customer;