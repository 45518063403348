import React from 'react';
import {Heading} from "@chakra-ui/react";

const TitleComponents = ({children}: { children: JSX.Element | string }) => {
  return (
    <Heading
      fontFamily='Roboto'
      py={2} fontWeight='500'
      textAlign='center'
      lineHeight='21px'
      fontSize='20px'
      w='full' mx='auto'
      letterSpacing='5%'
      color={"#202020"}
    >{children}</Heading>
  );
};

export default TitleComponents;