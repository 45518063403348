import React from 'react';
import {Text} from "@chakra-ui/react";
import {jsx} from "@emotion/react";
import JSX = jsx.JSX;


interface Props {
  children: string
  fontSize?: string;
  color?: string;
  fontWeight?: string
}
const DefText = ({children, fontWeight = '400', fontSize = '16px', color = '#202020'}: Props) => {
  return (
    <Text
      color={color}
      fontFamily='Roboto'
      fontWeight={fontWeight}
      lineHeight='20px'
      fontSize={fontSize}
    >
      {children}
    </Text>
  );
};

export default DefText;