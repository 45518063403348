import React from 'react';

const OthersSvg = ({size=26}: {size?: number}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.75 23.8333H3.25C2.80583 23.8333 2.4375 23.4649 2.4375 23.0208C2.4375 22.5766 2.80583 22.2083 3.25 22.2083H22.75C23.1942 22.2083 23.5625 22.5766 23.5625 23.0208C23.5625 23.4649 23.1942 23.8333 22.75 23.8333Z" fill="#9747FF"/>
      <path d="M20.605 3.76997C18.5033 1.6683 16.445 1.61413 14.2892 3.76997L12.9783 5.0808C12.87 5.18913 12.8267 5.36247 12.87 5.51413C13.6933 8.38497 15.99 10.6816 18.8608 11.505C18.9042 11.5158 18.9475 11.5266 18.9908 11.5266C19.11 11.5266 19.2183 11.4833 19.305 11.3966L20.605 10.0858C21.6775 9.02413 22.1975 7.99497 22.1975 6.95497C22.2083 5.88247 21.6883 4.84247 20.605 3.76997Z" fill="#9747FF"/>
      <path d="M16.9109 12.4909C16.5967 12.3392 16.2934 12.1875 16.0009 12.0142C15.7626 11.8734 15.5351 11.7217 15.3076 11.5592C15.1234 11.44 14.9067 11.2667 14.7009 11.0934C14.6792 11.0825 14.6034 11.0175 14.5167 10.9309C14.1592 10.6275 13.7584 10.2375 13.4009 9.8042C13.3684 9.78253 13.3142 9.7067 13.2384 9.6092C13.1301 9.4792 12.9459 9.26253 12.7834 9.01337C12.6534 8.85087 12.5017 8.61253 12.3609 8.3742C12.1876 8.0817 12.0359 7.7892 11.8842 7.48587C11.8519 7.41658 11.821 7.34778 11.7914 7.27958C11.6464 6.94518 11.2113 6.84797 10.9535 7.10573L4.70173 13.3575C4.56089 13.4984 4.43089 13.7692 4.39839 13.9534L3.81339 18.1025C3.70506 18.8392 3.91089 19.5325 4.36589 19.9984C4.75589 20.3775 5.29756 20.5834 5.88256 20.5834C6.01256 20.5834 6.14256 20.5725 6.27256 20.5509L10.4326 19.9659C10.6276 19.9334 10.8984 19.8034 11.0284 19.6625L17.273 13.4179C17.5326 13.1583 17.4345 12.7134 17.0964 12.5704C17.0356 12.5446 16.9739 12.5182 16.9109 12.4909Z" fill="#9747FF"/>
    </svg>

  );
};

export default OthersSvg;