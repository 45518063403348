export function toLocalStorage(key: string, value: string) {
  sessionStorage.setItem(key, value);
}

export function getLocalStorage(key: string): string {
  return sessionStorage.getItem(key) || "";
}

export function removeLocalStorage(key: string) {
  sessionStorage.removeItem(key);
}

export function hexToRGB (hex: any, opacity: number = 1){
  hex = hex.slice(1)
  hex = '0x' + hex
  let r = hex >> 16 & 0xFF
  let g = hex >> 8 & 0xFF
  let b = hex & 0xFF
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export const getFormatDate = (date: Date | string): string => {
  const _date = new Date(date);
  const timestamp = _date.toLocaleTimeString();
  const day = _date.getDate();
  const month = _date.getMonth() + 1; // Месяцы в JavaScript начинаются с 0, поэтому добавляем 1
  const year = _date.getFullYear();
  return `${day.toString().padStart(2, "0")}.${month
    .toString()
    .padStart(2, "0")}.${year} / ${timestamp}`;
};

export const navigateToUrl = (url: string, _self?: boolean) => {
  if (_self) {
    window.open(url, "_self");
  } else {
    window.open(url, "_blank");
  }
};

