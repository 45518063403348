import React, {useEffect, useState} from 'react';
import {Box, Flex, Heading, Spinner, Text} from "@chakra-ui/react";
import {GoDotFill} from "react-icons/go";
import {hexToRGB, navigateToUrl} from "../../../config/helpers";
import MiniButton from "../Buttons/MiniButton";
import {ICommentOrder, ICommenttext, IFollowOrder, ILikeOrder} from "../../../models/tasks";
import {useAppDispatch, useAppSelector} from "../../../config/hooks/redux";
import {executeOrders, TypeOrders} from "../../../pages/customer/reducer/actions";
import toast from "react-hot-toast";
import {IoCopyOutline} from "react-icons/io5";
import {LuDot} from "react-icons/lu";
import DefText from "../text/DefText";
import copy from "copy-to-clipboard";
import moment from 'moment';
import 'moment/locale/ru';

interface Props {
  color: string
  icon: JSX.Element
  order: ILikeOrder | IFollowOrder | ICommentOrder
  topLeft: string
  middleLeft: string
  role: number
  order_type: TypeOrders
  comments?: ICommenttext[]
}


const OrderCardItems = ({
                          middleLeft,
                          order,
                          topLeft,
                          icon,
                          color,
                          role,
                          order_type,
                          comments,
                        }: Props) => {
  const dispatch = useAppDispatch()
  const [isLoading, setLoad] = useState(false)
  const {account_id} = useAppSelector(s => s.ProfileReducer.profile)

  function toInstagram() {
    navigateToUrl(order.url, true)
  }

  const onCheckOrder = () => {
    if (!!account_id) {
      dispatch(executeOrders(account_id, order.id, setLoad, order_type, toInstagram))
    } else {
      toast.error('Необходимо связать инстаграм аккаунт')
    }
  }

  const handleOnCopy = (comment: string) => {
    if (!!comment) {
      copy(comment)
      toast('Скопирован')
    }
  };
  useEffect(() => {
    moment.locale('ru'); // Устанавливаем локализацию на русский язык
  }, []);

  const lastSeen = moment(order.created_at).fromNow();

  const isActual: boolean = order.applied_quantity !== order.quantity
  const actualText = isActual ? "Актуально" : "Не актуально"
  return (
    <Flex justifyContent='space-between' alignItems='start' h='100%' w='100%' fontFamily='Roboto'>
      <Flex h='100%' w='100%'>
        <Flex flexDirection='column' justifyContent='space-between' h='100%' mr={3}>
          {icon}
        </Flex>
        <Flex flexDirection='column' h='100%' w='100%' mr={3}>

          <Flex justifyContent='space-between' alignItems='center' w='100%' mt='2px'>
            <Text fontSize='14px' fontWeight='400' lineHeight='21px' color='#3E3526' opacity='0.5'>{topLeft}</Text>
            <Flex alignItems='center'>
              <Text fontSize='10px' fontWeight='400' lineHeight='15px' color='#3E3526'
                    opacity='0.5'>{!!role ? actualText : order.status_label}</Text>
              <Box ml='5px'><GoDotFill color={color}/></Box>
            </Flex>
          </Flex>

          <Flex justifyContent='space-between' alignItems='center' w='100%' mt={4}>
            <Text fontSize='16px' fontWeight='400' lineHeight='21px' color='#3E3526'>{middleLeft}</Text>
            <Flex alignItems='center' fontSize='16px' fontWeight='400' lineHeight='21px' color='#3E3526'><Text
              color='#4BD340'>{order.applied_quantity}</Text> &nbsp;/ {order.quantity}</Flex>
          </Flex>

          {order_type === "comment" && <Box mt={2}>
            <Heading fontWeight='600' fontSize='16px' lineHeight='21px' mb='6px'>Варианты комментариев к
              посту: &nbsp; {comments?.length}</Heading>
            <Box>
              {comments?.map((comment, idx) => (
                <Flex key={idx} mt='14px' alignItems='center'>
                  <Box
                    onClick={() => handleOnCopy(comment.text)}
                    color='#0297F6' fontSize='1.2rem'
                    cursor='pointer' _active={{opacity: '.7'}}
                  ><IoCopyOutline/></Box>
                  <LuDot/>
                  <DefText children={comment.text}/>
                </Flex>
              ))}
            </Box>
          </Box>}

          <Flex justifyContent='space-between' alignItems='end' w='100%' mt={4}>
            <Text fontSize='14px' fontWeight='400' lineHeight='21px' color='#3E3526'
                  opacity='0.5'>{lastSeen}</Text>
            <Box w={!role ? '122px' : '112px'}>
              {!role && <MiniButton
                color={color}
                bg={hexToRGB(color, 0.2)}
              >{order.status_label}</MiniButton>}
              {isLoading && <Flex w='100%' justifyContent='end'><Spinner color='#0297F6'/></Flex>}
              {!!role && !isLoading && <MiniButton
                disabled={!isActual || isLoading}
                onClick={onCheckOrder}
              >{isActual ? 'Выполнить' : 'Выполнен'}</MiniButton>}
            </Box>
          </Flex>


        </Flex>
      </Flex>

    </Flex>
  );
};

export default OrderCardItems;