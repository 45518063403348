import React from 'react';
import {Avatar, Box, Button, Stack} from "@chakra-ui/react";
import {BsPlusLg} from "react-icons/bs";
import {BiSolidTrashAlt} from "react-icons/bi";
import {useAppDispatch, useAppSelector} from "../../../config/hooks/redux";
import FileInput from "../../../components/ui/inputs/FileInput";
import {editProfile} from "../../../components/profile-header/reducer/actions";

const AvatarLoad = () => {
  const fileReader: FileReader = new FileReader()
  const {avatar, id} = useAppSelector(state => state.ProfileReducer.profile)
  const {isLoading} = useAppSelector(state => state.LoginReducer)
  const dispatch = useAppDispatch()
  const handleChange = (e: React.ChangeEvent<any>) => {
    let formData = new FormData()
    const file: File = e.target.files[0]
    if (!file) return;
    formData.set('avatar', file)
    fileReader.onload = ev => {
      dispatch(editProfile(formData, id, () => {
      }))
    }
    fileReader.readAsDataURL(file)
  }
  const removeAvatar = () => {
    let formData = new FormData()
    formData.set('avatar', "")
    dispatch(editProfile(formData, id, () => {
    }))
  }

  return (
    <div>
      <Box mx='auto' p='15px' position='relative'>
        {avatar && <Avatar
          objectFit='cover'
          w='103px'
          h='103px'
          src={avatar}
        />}

        {!avatar && <Avatar
          objectFit='cover'
          w='103px'
          h='103px'
        />}
        <Box position='absolute' bottom='16px' right='0' w='41px' overflow='hidden'>
          <Stack as='label' w='100%' h='41px' rounded='50%' zIndex='2' position='relative' display='inline-block'>
            <FileInput
              handleChange={handleChange}
              accept={['.png', '.jpg', '.HEIC', '.jpeg', '.svg', '.gif']}
              multi={false}/>
            <Button
              as='span'
              position='relative'
              display='inline-block'
              cursor='pointer' w='100%' h='100%'
              outline='none' variant='none'
              rounded='50%' bg={'#0080DDFF'} mx='auto' p='0'
              _focus={{border: 'none'}}
              _active={{bg: 'white'}}
            ><Button
              variant='none' as='div' p='0'
              color='#FFFFFF' fontSize='1.4rem'
              _active={{color: '#0080DDFF'}}
              w='100%' h='100%' justifyContent='center'
              alignItems='center' rounded='50%'>
              <BsPlusLg/></Button>
            </Button>
          </Stack>
        </Box>
        <Box position='absolute' bottom='16px' right='-45px'>
          {!!avatar && <Button
            onClick={removeAvatar}
            isDisabled={isLoading}
            variant='none' fontSize='1.6rem' p='0'
            w='37px' h='37px' rounded='4px'
            bg='#FFFFFF1A' color='#D92C41' _active={{bg: '#FFFFFF4C'}}
          ><BiSolidTrashAlt/>
          </Button>}
        </Box>
      </Box>
    </div>
  );
};

export default AvatarLoad;