import React, {useState} from 'react';
import DefButton from "../../ui/Buttons/DefButton";
import {navigateToUrl} from "../../../config/helpers";
import {addMoney, getMoney} from "../actions";
import {useAppSelector} from "../../../config/hooks/redux";
import {
  Box,
  Button,
  Center,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay, Spinner,
  useDisclosure
} from "@chakra-ui/react";
import DefText from "../../ui/text/DefText";
import InputComponent from "../../ui/inputs/InputComponent";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import SomSvg from "../../../assets/svg/SomSvg";
import toast from "react-hot-toast";

const GetMoneyButton = () => {
  const {balance} = useAppSelector(s => s.ProfileReducer.profile)
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [requisites, setRequisites] = useState([""])
  const [som, setSome] = useState("")
  const [isLoading, setLoading] = useState(false)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSome(e.target.value)
  }

  const handleChangeComment = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    setRequisites([...requisites.fill(e.target.value, index, index + 1)])
  }

  const addInput = () => {
    if (requisites.every(el => !!el.trim())) {
      setRequisites([...requisites, ""])
    }else toast('заполняйте форму')
  }

  const removeInput = (index: number) => {
    setRequisites([...requisites.filter((_, idx) => idx !== index)])
  }

  const handleClick = (index: number) => {
    if (index === requisites.length - 1) {
      addInput()
    } else {
      removeInput(index)
    }
  }

  function successCLose(){
    onClose()
    setSome('')
    setRequisites([""])
  }

  const getBalance = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (balance >= +som){
      getMoney({requisites: requisites.join(';   '), amount: som}, setLoading, successCLose)
    }else {
      toast.error("Недостаточное средство")
    }
  }

  const enoughBalance = balance >= 200
  return (
    <>
      <DefButton onClick={onOpen} type='button'>Запросить деньги</DefButton>

      <Modal isOpen={isOpen} onClose={onClose} size={{sm: 'md', base: !enoughBalance ? 'md' : 'xs'}} isCentered={!enoughBalance}>
        <ModalOverlay bg='#000000B2'/>
        {!enoughBalance && <ModalContent rounded='24px' px='30px' pt='40px' pb='29px' h='160px'>
          <ModalCloseButton/>
          <Flex justifyContent='center' alignItems='center' w='100%'>
            <Box textAlign='center' opacity='0.6' w={{sm: '57%', base: '100%'}}>
              <DefText>Чтобы запросить деньги, повысьте баланс до 200с</DefText>
            </Box>
          </Flex>
          <Button
            onClick={onClose}
            mt='18px' bg='transparent'
            w='60%' mx='auto'
          >ОК</Button>
        </ModalContent>}


        {enoughBalance && <ModalContent rounded='24px' px='20px' pt='20px' pb='20px'>
          <ModalCloseButton/>
          <Flex justifyContent='center' alignItems='center' w='100%' mb={2}>
            <Box textAlign='center' opacity='0.6' w={{sm: '70%', base: '100%'}}>
              <DefText>Напишите пожалуйста свои реквизиты в таком формате:</DefText>
            </Box>
          </Flex>

          <Flex flexDirection='column' opacity='.9' alignItems='center' justifyContent='center' mb={2}>
            <DefText fontWeight='500'>цифры  -  название</DefText>
            <DefText fontWeight='500'>+99655505902 - MBank / Optima и др.</DefText>
          </Flex>

          <form onSubmit={getBalance}>
            {requisites.map((el, idx) => (
              <InputComponent
                key={idx}
                mini={true}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeComment(idx, e)}
                onCLick={() => handleClick(idx)}
                value={el}
                variant='flushed'
                name={'requisites'}
                placeholder={"Реквизиты"}
                type={"text"}
                required={true}
                rightIcon={
                  idx === requisites.length - 1 ?
                    <Box color='#0297F6'><AiOutlinePlus/></Box> : <Box color='#FE6D72'><AiOutlineMinus/></Box>
                }
              />
            ))}
            <Box mt={4} mb={2}>
              <DefText fontSize='13px'>Какую сумму хотели снять?</DefText>
            </Box>
            <InputComponent
              handleChange={handleChange}
              value={som}
              name={"som"}
              placeholder={"Сумма"}
              leftIcon={<SomSvg/>}
              type={"number"}
              required={true}
              mini={true}
            />
            <Center>
              {isLoading ? <Spinner color='#0297F6'/> : <Button
                type="submit"
                mt='18px' bg='#0297F6'
                w='60%' mx='auto' color='#FFFFFF'
              >запросить</Button>}
            </Center>
          </form>
        </ModalContent>}
      </Modal>
    </>
  );
};

export default GetMoneyButton;